import {Route} from "types/route";
import {HomeView} from "views/HomeView";
import {LoginView} from "views/LoginView";
import {CampaignsView} from 'views/Campaigns'
import {BonsView} from 'views/Bons';
import {BuyBonsView} from 'views/Bons/Buy';
import {ColleactPointsView} from "views/CollectPoints";
import {MyProfileView} from "views/Profile/MyProfile";
import {WalletView} from "views/Profile/Wallet";
import {SettingsView} from "views/Profile/Settings";
import {NewCampaignsView} from 'views/Campaigns/new';
import {NewBonView} from 'views/Bons/New';
import {ProfileView} from "views/Profile";
import {YoursBonsView} from "views/Bons/YoursBons";
import {PartnersView} from "views/Profile/Partners";
import {YoursCampaignsView} from "views/Campaigns/YoursCampaignsView";
import {BoughtBonsView} from "views/Bons/BoughtBons";
import {RegisterView} from "views/Register";
import {ResetPasswordView} from 'views/ResetPassword';
import {ChangePasswordView} from 'views/ChangePassword';
import {PrivacyPolicyView} from "./views/PrivacyPolicy";
import {RegulaminView} from "./views/Regulamin";
import {PaymentsView} from "./views/Profile/Payments";

export const routes: Array<Route> = [
  {
    path: "/regulamin",
    exact: true,
    component: RegulaminView,
  },
  {
    path: "/privacy-policy",
    exact: true,
    component: PrivacyPolicyView,
  },
  {
    path: "/login",
    exact: true,
    component: LoginView,
  },
  {
    path: "/register",
    exact: true,
    component: RegisterView,
  }, {
    path: "/password-reset",
    exact: true,
    component: ResetPasswordView,
  },
  {
    path: "/password-reset/new-password",
    exact: true,
    component: ChangePasswordView,
  },
  {
    path: "/",
    exact: true,
    component: HomeView,
    private: true,
  },
  {
    path: "/bons",
    exact: false,
    component: BonsView,
    private: true,
    routes: [
      {
        path: "/bons/buy",
        exact: true,
        component: BuyBonsView,
        private: true,
      },
      {
        path: "/bons/boughtBons",
        exact: true,
        component: BoughtBonsView,
        private: true
      },
      {
        path: "/bons/yoursBons",
        exact: true,
        component: YoursBonsView,
        private: true,
      },
      {
        path: "/bons/new",
        exact: true,
        component: NewBonView,
        private: true,
      }
    ]
  },
  {
    path: "/campaigns",
    exact: false,
    component: CampaignsView,
    private: true,
    routes: [
      {
        path: "/campaigns/yours",
        exact: true,
        component: YoursCampaignsView,
        private: true
      },
      {
        path: "/campaigns/new",
        exact: true,
        component: NewCampaignsView,
        private: true
      }
    ]
  },
  {
    path: "/points",
    exact: true,
    component: ColleactPointsView,
    private: true,
  },
  {
    path: "/payments/:id",
    exact: true,
    component: PaymentsView,
    private: true,
  },
  {
    path: "/profile",
    exact: false,
    component: ProfileView,
    routes: [
      {
        path: "/profile/info",
        exact: true,
        component: MyProfileView,
        private: true,
      },
      {
        path: "/profile/wallet",
        exact: true,
        component: WalletView,
        private: true,
      },
      // {
      //   path: "/profile/boughtBons",
      //   exact: true,
      //   component: BoughtBonsView,
      //   private: true
      // },
      {
        path: "/profile/settings",
        exact: true,
        component: SettingsView,
        private: true,
      }, {
        path: "/profile/partners",
        exact: true,
        component: PartnersView,
        private: true,
      },
    ]
  }
];
