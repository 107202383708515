import React from "react";
import styled from "styled-components";

const Root = styled.div`
  ${({ theme }) => `
    border-radius: 16px;
    background-color: ${theme.palette.neutral.white};
    overflow: hidden;
    box-shadow: 0 8px 24px -8px ${theme.palette.neutral.medium};
  `};
`;

export const Card: React.FC = ({ children, ...props }) => (
  <Root {...props}>{children}</Root>
);
