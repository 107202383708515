import { fetchAPI } from 'utilities/fetchAPI';
import { USER_PROFILE_URL, MY_PROFILE } from 'constans/path';
import { IProfile, IUser } from 'types/user';

export const fetchMyData = () => fetchAPI<{data: IUser}>(MY_PROFILE).then(user => user.data);
export const fetchUserData = () => fetchAPI<{profile: IProfile}>(USER_PROFILE_URL);

export interface IProfilePartner {
  company_name: string;
  nip: string;
  company_city: string;
  company_street: string;
  company_street_number: string;
  company_flat_number: string;
  company_zip_code: string;
  company_country: string;
  avatar: null
}

export const updatePartnerProfileService = (data: IProfilePartner) => fetchAPI(USER_PROFILE_URL, {
  method: 'POST',
  body: JSON.stringify({profile: data})
})
