import React, { useEffect, useState } from "react";
import { Box } from "components/Box";
import { BorderedCard } from "components/BorderedCard";
import { Input } from "components/Input";
import { Typography } from "components/Typography";
import { Flex } from "./Flex";
import { ReactComponent as OrocoinIcon } from "icons/orocoin.svg";
import { Button } from "./Button";
import { DatePickerInput } from "components/DatePickerInput";
import { useForm, Controller } from "react-hook-form";
import { IVoucher } from "services/vouchers";
import { SelectField } from "components/Select";
import styled from "styled-components";
import { Textarea } from "components/Textarea";
import { FileUpload } from "components/FileUpload";
import { voucherSchema } from "schemas/voucher";
import { yupResolver } from "@hookform/resolvers/yup";

interface IProps {
  openConfirmModal: (voucher: IVoucher) => void;
}
interface IRowFields {
  bordered?: boolean;
}

const WrapperField = styled(Box)`
  width: 100%;
`;
const Form = styled.form`
  width: 100%;
`;
const RowFields = styled(Flex)<IRowFields>`
  ${({ theme, bordered }) => `
      padding: ${theme.space[6]}px 0;
    ${
      bordered &&
      `
        border-bottom: 1px solid ${theme.palette.neutral.light};
      `
    }
  `}
`;
const InlineFlex = styled.div`
  display: inline-flex;
  align-items: center;
`;

const Field = styled.div`
  width: 48%;
`;

const options = [
  { value: "percent", label: "%" },
  { value: "zloty", label: "PLN" },
];

const defaultValues = {
  // kind: "",
  name: "",
  money_value: 0,
  percent_value: 0,
  voucher_price: 0,
  count: 0,
  website_address: "",
  start_at: "",
  end_at: "",
  image: "",
  voucher_codes: "",
};

export const AddBonForm: React.FC<IProps> = ({ openConfirmModal }) => {
  const [vouchersCount, setVouchersCount] = useState<string | null>(null);
  const [kindValue, setKindValue] = useState<"percent" | "zloty">("percent");
  const {
    handleSubmit,
    register,
    watch,
    control,
    setValue,
    formState: { errors },
  } = useForm<IVoucher>({
    defaultValues,
    resolver: yupResolver(voucherSchema),
  });

  const { voucher_codes } = watch();

  const handleChangeKindValue = ({
    value,
  }: {
    value: "percent" | "zloty";
    label: string;
  }) => {
    setKindValue(value);
  };

  const handleMaxPercentFieldValue = (value: number) => {
    if (value > 100) setValue("percent_value", 100);
    else if (value < 0) setValue("percent_value", 0);
    else setValue("percent_value", value);
  };

  const onSubmit = (voucher: IVoucher) => {
    const watcher = watch();

    if (!watcher.money_value) {
      const changeValue = { ...watcher, money_value: null };
      openConfirmModal(changeValue);
    } else if (!watcher.percent_value) {
      const changeValue = { ...watcher, percent_value: null };
      openConfirmModal(changeValue);
    }
  };

  useEffect(() => {
    if (voucher_codes.endsWith(",")) return;
    const codeWitchoutSpace = voucher_codes.split(" ").join("");
    setValue("voucher_codes", codeWitchoutSpace);
    setVouchersCount(voucher_codes);
    setValue("count", voucher_codes.split(",").length);
  }, [voucher_codes]);

  return (
    <BorderedCard>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <WrapperField px={15} py={6}>
          <RowFields
            justifyContent="space-between"
            flexDirection="column"
            bordered
          >
            <Box mb={2}>
              <Typography variant="h3">Opis bona</Typography>
            </Box>
            <Input
              {...register("name")}
              error={errors.name && errors.name.message}
            />
          </RowFields>
          <RowFields justifyContent="space-between" bordered>
            <Field>
              <Box mb={2}>
                <Typography variant="h3">Wartość bona</Typography>
              </Box>
              <Flex>
                <Box mr={5}>
                  {kindValue === "percent" ? (
                    <Input
                      {...register("percent_value")}
                      error={
                        errors.percent_value && errors.percent_value.message
                      }
                      type="number"
                      max="100"
                      onChange={(val) =>
                        handleMaxPercentFieldValue(
                          Number(val.currentTarget.value)
                        )
                      }
                    />
                  ) : (
                    <Input
                      {...register("money_value")}
                      error={errors.money_value && errors.money_value.message}
                      type="number"
                    />
                  )}
                </Box>
                <SelectField
                  options={options}
                  onChange={(val) => handleChangeKindValue(val)}
                />
              </Flex>
            </Field>
            <Field>
              <Box mb={2}>
                <Typography variant="h3">Koszt bona</Typography>
              </Box>
              <InlineFlex>
                <Box mr={2}>
                  <Input
                    {...register("voucher_price")}
                    error={errors.voucher_price && errors.voucher_price.message}
                    type="number"
                  />
                </Box>
                <OrocoinIcon />
              </InlineFlex>
            </Field>
          </RowFields>
          <RowFields flexDirection="column">
            <Box mb={2}>
              <Typography variant="h3">Adres strony</Typography>
            </Box>
            <Input
              {...register("website_address")}
              error={errors.website_address && errors.website_address.message}
            />
          </RowFields>
          <RowFields justifyContent="space-between" bordered>
            <Field>
              <Box mb={2}>
                <Typography variant="h3">Unikalne kody</Typography>
              </Box>
              <Flex>
                <Textarea
                  {...register("voucher_codes")}
                  error={errors.voucher_codes && errors.voucher_codes.message}
                  placeholder="kod1,kod2,kod3"
                />
              </Flex>
            </Field>
            <Field>
              <Box mb={2}>
                <Typography variant="h3">Dodaj zdjęcie bona</Typography>
              </Box>
              <Flex>
                <Controller
                  control={control}
                  name="image"
                  render={({ field }) => (
                    <FileUpload
                      onChange={(val) => {
                        console.log("val", val);
                        return field.onChange(val);
                      }}
                    />
                  )}
                />
              </Flex>
            </Field>
          </RowFields>

          <RowFields justifyContent="space-between" bordered>
            <Field>
              <Box mb={2}>
                <Typography variant="h3">Data ważności</Typography>
              </Box>
              <Flex alignItems="center">
                {/* dodać controller */}
                <Controller
                  control={control}
                  name="start_at"
                  render={({ field }) => (
                    <DatePickerInput
                      error={!!errors.start_at}
                      day={field.value ? new Date(field.value) : undefined}
                      onChange={(date) =>
                        field.onChange(date ? new Date(date) : undefined)
                      }
                    />
                  )}
                />
                <Box mx={2}>
                  <Typography variant="body">do</Typography>
                </Box>
                <Controller
                  control={control}
                  name="end_at"
                  render={({ field }) => (
                    <DatePickerInput
                      error={!!errors.start_at}
                      day={field.value ? new Date(field.value) : undefined}
                      onChange={(date) =>
                        field.onChange(date ? new Date(date) : undefined)
                      }
                    />
                  )}
                />
              </Flex>
            </Field>
            <Field>
              <Box mb={2}>
                <Typography variant="h3">Ilość wszystkich bonów</Typography>
              </Box>
              <InlineFlex>
                <Box mr={2}>
                  <Typography variant="body3">
                    {vouchersCount ? vouchersCount.split(",").length : "0"}
                  </Typography>
                </Box>
                <OrocoinIcon />
              </InlineFlex>
            </Field>
          </RowFields>
          <Flex justifyContent="flex-end">
            <Box mt={6}>
              <Button label="Dodaj bon" />
            </Box>
          </Flex>
        </WrapperField>
      </Form>
    </BorderedCard>
  );
};
