import { createGlobalStyle } from "styled-components";

export const GlobalStyles = createGlobalStyle`
  ${({ theme }) => `
    html {
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
    }

    *,
    *:before,
    *:after {
      -webkit-box-sizing: inherit;
      -moz-box-sizing: inherit;
      box-sizing: inherit;
    }

    body {
      margin: 0;
      font-family: sans-serif;
      font-size: 14px;
      line-height: 1.4;
      margin-top: 100px;
      min-height: calc(100vh - 100px);
    }

    p {
      padding: 0;
      margin: 0;
    }
    ul {
      margin: 0;
      padding: 0;
    }
    li {
      list-style: none;
    }
    select {
      font-family: sans-serif;
    }
  `}
`;