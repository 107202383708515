import React from "react";
import { Box } from "components/Box";
import { Typography } from "components/Typography";
import { CountCoin } from "components/CountCoin";
import { Flex } from "components/Flex";
import { Col, Row } from "react-grid-system";
import styled from "styled-components";

interface IProps {
  date: string;
  label: string;
  subLabel?: string;
  variant: "plus" | "minus";
  amount: number;
}

const Root = styled(Box)`
  background-color: #f7f7f7;
  border-radius: 20px;
`;

export const HistoryPaymentRow: React.FC<IProps> = ({
  date,
  label,
  subLabel,
  variant,
  amount,
}) => (
  <Root px={6} py={3} mb={3}>
    <Row>
      <Col sm={4}>
        <Typography variant="body3">{date}</Typography>
      </Col>
      <Col sm={4}>
        <Typography variant="body3">{label}</Typography>
        {subLabel && <Typography variant="body2">{subLabel}</Typography>}
      </Col>
      <Col sm={4}>
        <Typography variant="body3">
          <Box mr={6}>
            <Flex justifyContent="flex-end">
              <Box pr={2}>{variant === "plus" ? "+" : "-"}</Box>{" "}
              <CountCoin label={amount} />
            </Flex>
          </Box>
        </Typography>
      </Col>
    </Row>
  </Root>
);
