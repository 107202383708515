import { Box } from "components/Box";
import { Button } from "components/Button";
import { Flex } from "components/Flex";
import { Input } from "components/Input";
import { BorderedCard } from "components/BorderedCard";
import { Typography } from "components/Typography";
import { Checkbox } from "components/Checkbox";
import { DatePickerInput } from "components/DatePickerInput";
import { useForm, Controller } from "react-hook-form";
import { RegisterUserService, IRegisterUserRequest } from "services/auth";
import { registerSchema } from "schemas/register";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { yupResolver } from "@hookform/resolvers/yup";

interface IForm extends IRegisterUserRequest {
  confirm_regulations: boolean;
}

const StyledBorderedCard = styled(BorderedCard)`
  display: block;
  width: 600px;
  margin: 0 auto;
`;
const SytyledInput = styled(Input)`
  width: 95%;
`;
const WrapperController = styled.div`
  width: 100%;
`;
const Link = styled.span`
  text-decoration: underline;
  cursor: pointer;
`;

const defaultValues = {
  first_name: "",
  last_name: "",
  birthdate: "",
  email: "",
  password: "",
  password_confirmation: "",
  confirm_regulations: false,
};

export const RegisterView = () => {
  const history = useHistory();
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<IForm>({
    defaultValues,
    resolver: yupResolver(registerSchema),
  });

  const handleMoveToLoginView = () => {
    history.push("/login");
  };

  const registerUser = (user: IRegisterUserRequest) => {
    RegisterUserService(user)
      .then((response) => {
        toast.success("Konto zostało utowrzone.");
        setTimeout(() => {
          history.push("/login");
        }, 1000);
      })
      .catch(({ error }) => {
        if (error) {
          toast.error(error);
        } else {
          toast.error("Nie udało się założyć konta.");
        }
      });
  };

  const onSubmit = handleSubmit(async (data: IRegisterUserRequest) => {
    console.log("data", data);
    registerUser(data);
  });

  return (
    <form onSubmit={onSubmit}>
      <StyledBorderedCard>
        <Box p={8}>
          <Box mb={9}>
            <Typography variant="h1">Zarejestruj się</Typography>
          </Box>
          <Box mb={10}>
            <Flex justifyContent="space-between">
              <SytyledInput
                label="Imię"
                type="text"
                error={errors.first_name && errors.first_name.message}
                placeholder="Jan"
                {...register("first_name")}
              />
              <Input
                label="Nazwisko"
                type="text"
                error={errors.last_name && errors.last_name.message}
                placeholder="Kowalski"
                {...register("last_name")}
              />
            </Flex>
          </Box>
          <Box mb={10}>
            <Flex>
              <SytyledInput
                label="E-mail"
                type="text"
                error={errors.email && errors.email.message}
                placeholder="jan.kowalski@email.com"
                {...register("email")}
              />
              <WrapperController>
                <Controller
                  control={control}
                  name="birthdate"
                  render={({ field }) => (
                    <DatePickerInput
                      label="Data urodzenia"
                      error={!!errors.birthdate}
                      day={field.value ? new Date(field.value) : undefined}
                      onChange={(date) =>
                        field.onChange(date ? new Date(date) : undefined)
                      }
                    />
                  )}
                />
              </WrapperController>
            </Flex>
          </Box>
          <Box mb={10}>
            <Flex>
              <SytyledInput
                label="Hasło"
                type="password"
                error={errors.password && errors.password.message}
                placeholder="Hasło"
                {...register("password")}
              />
              <Input
                label="Powtótrz hasło"
                type="password"
                error={
                  errors.password_confirmation &&
                  errors.password_confirmation.message
                }
                placeholder="Powtórz hasło"
                {...register("password_confirmation")}
              />
            </Flex>
          </Box>

          <Box mt={10}>
            <Controller
              control={control}
              name="confirm_regulations"
              render={({ field }) => (
                <Checkbox
                  onChange={field.onChange}
                  error={!!errors.confirm_regulations}
                  label="Akceptuję regulamin i politykę prywatności*"
                />
              )}
            />
            <Box mt={3}>
              <Checkbox label="Wyrażam zgodę na otrzymywanie drogą elektroniczną informacji handlowej wysyłanej przez LikeY." />
            </Box>
          </Box>

          <Flex justifyContent="flex-end">
            <Box my={9}>
              <Button type="submit" label="Zarejestruj się" />
            </Box>
          </Flex>

          <Typography variant="body" textAlign="center">
            Masz już konto?{" "}
            <Link onClick={handleMoveToLoginView}>zaloguj się</Link>
          </Typography>
        </Box>
      </StyledBorderedCard>
    </form>
  );
};
