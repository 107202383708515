import React, { useState, useEffect } from "react";
import { CardPoint } from "components/PointCard";
import { fetchCampaingsApi, ICampaing } from "services/campaign";
import { Box } from "components/Box";
import { Row, Col } from "react-grid-system";
import { toast } from "react-toastify";
import { Pagination } from "components/Pagination";
import { ConnectFacebookCard } from "components/ConnectFacebookCard";
import { Flex } from "components/Flex";
import { usePagination } from "hooks/pagination";
import { USER_ROLE } from "enums/role";
import { useAuthStateContext } from "contexts/AuthContext";
import styled from "styled-components";

const BackgroundTitle = styled.div`
  width: 100%;
  padding: 5px 0;
`;

export const ColleactPointsView = () => {
  const [points, setPoints] = useState<ICampaing[]>([]);
  const [totalCount, setTotalCount] = useState(0);

  const { currentPage, handleClick, itemsPerPage } = usePagination();

  const { userProfile } = useAuthStateContext();

  useEffect(() => {
    fetchCampaingsApi(currentPage, itemsPerPage)
      .then((response) => {
        setTotalCount(response.pagination.count);
        setPoints(response.data);
      })
      .catch(() => toast.error("Błąd podczas pobierania listy kampanii."));
  }, [currentPage]);

  return (
    <Box>
      <BackgroundTitle>
        <h1>Zbieraj punkty za reakcje!</h1>
      </BackgroundTitle>
      <Row>
        {points.map((point, index) => (
          <React.Fragment key={point.id}>
            <Col key={point.id} sm={8}>
              <CardPoint {...point} />
            </Col>
            {index === 0 && (
              <Col sm={4}>
                {userProfile?.role.kind === USER_ROLE.user && (
                  <ConnectFacebookCard />
                )}
              </Col>
            )}
          </React.Fragment>
        ))}
      </Row>
      <Row>
        <Col sm={8}>
          <Flex justifyContent="flex-end">
            <Pagination
              current={currentPage}
              pages={totalCount}
              itemsPerPage={itemsPerPage}
              onPageClick={handleClick}
            />
          </Flex>
        </Col>
      </Row>
    </Box>
  );
};
