import styled, {css} from "styled-components";
import {space, SpaceProps} from "styled-system";

export const Table = styled.table<SpaceProps & {unfixed?: boolean}>(
  ({theme, unfixed}: any) => css`
    width: 100%;
    table-layout: ${unfixed ? 'auto' : 'fixed'};
    border-collapse: collapse;
    border: 1px solid;
    
    & th, td {
      padding: 6px;
      font-size: 16px;
    }
    & tr > th {
      background-color: ${theme.palette.primary.main};
      border: 1px solid;
    }
    & tr > td {
      word-break: break-word;
    }
    & td {
      text-align: left;
      border: 1px solid;
    }
  `,
  space
);

