import { InputHTMLAttributes } from "react";
import styled from "styled-components";

interface ICheckbox extends InputHTMLAttributes<HTMLInputElement> {
  label: string;
  error?: boolean;
}
interface IControl {
  disabled?: boolean;
  error?: ICheckbox["error"];
}

const Label = styled.label`
  ${({ theme }) => `
    display: flex;
    color: ${theme.palette.text.grey};
    align-items: center;
    font-size: 16px;
    line-height: 18px;
    font-weight: 400;
    cursor: pointer;
  `}
`;
const Control = styled.div<IControl>`
  ${({ theme, disabled, error }) => `
    flex: 0 0 auto;
    display: block;
    height: 16px;
    width: 16px;
    margin-right: ${theme.space[2]}px;
    background-color: ${theme.palette.neutral.white};
    ${disabled ? "background-color: #dadada" : ""}
    ${
      error
        ? `
    border: 1px solid ${theme.palette.text.error};  
    `
        : `
    border: 1px solid rgba(30,46,102,0.15);  
    `
    }
  `}
`;

const Input = styled.input`
  ${({ theme }) => `
    position: absolute;
    visibility: hidden;
    width: 0;
    height: 0;

    &:checked {
      + ${Control} {
        border: 3px solid green;
      }
    }
  `}
`;

export const Checkbox: React.FC<ICheckbox> = ({
  className,
  label,
  ...props
}) => (
  <Label className={className}>
    <Input {...props} type="checkbox" />
    <Control disabled={props.disabled} error={props.error} />
    {label}
  </Label>
);
