import styled from "styled-components";
import { Flex } from "./Flex";

const StyledFlex = styled(Flex)`
  position: relative;
  border: 1px solid #dedee6;
  border-radius: 20px; ;
`;

export const BorderedCard: React.FC = ({ children, ...props }) => (
  <StyledFlex alignItems="center" {...props}>
    {children}
  </StyledFlex>
);
