import { useState } from "react";
import { Avatar } from "components/Avatar";
import { Box } from "components/Box";
import { Button } from "components/Button";
import { Typography } from "components/Typography";
import { AddPartnerModal } from "components/AddPartnerModal";
import { IProfilePartner, updatePartnerProfileService } from "services/user";
import { InfoBox } from "components/InfoBox";
import { useAuthStateContext } from "contexts/AuthContext";
import { toast } from "react-toastify";
import { BorderedCard } from "components/BorderedCard";
import { Flex } from "components/Flex";
import { MessageModal } from "components/MessageModal";
import styled from "styled-components";

const StyledFlex = styled(Flex)`
  width: 100%;
`;
const Wrapper = styled(Box)`
  width: 100%;
`;
const StyledBox = styled(Box)`
  width: 70%;
`;

export const PartnersView = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [displayMessageModal, setDisplayMessageModal] = useState(false);

  const { userProfile } = useAuthStateContext();

  const handleConfrim = (data: IProfilePartner) => {
    const req = { ...data, company_country: "Polska", avatar: null };
    updatePartnerProfileService(req)
      .then((res) => {
        setIsOpen(false);
        setTimeout(() => {
          setDisplayMessageModal(true);
        }, 500);
        toast.success("Uzupełniłeś dane partnera.");
      })
      .catch(() => toast.error("Nie udało się uzuepłnić danych firmy."));
  };

  return (
    <>
      {userProfile?.role.kind === "unverified_partner" ||
      userProfile?.role.kind === "partner" ? (
        <Box p={8}>
          <Box mb={3}>
            <Flex justifyContent="space-between" alignItems="baseline">
              <Box>
                <Typography variant="h2">Dane firmy</Typography>
              </Box>
              {userProfile?.role.kind === "unverified_partner" && (
                <InfoBox variant="warring" label="Oczekuje na weryfikację" />
              )}
            </Flex>
          </Box>
          <BorderedCard>
            <Wrapper p={6}>
              <StyledFlex justifyContent="space-between">
                <Avatar size="large" />
                <StyledBox p={6}>
                  <Flex justifyContent="space-between">
                    <Typography variant="body3">Nazwa firmy</Typography>
                    <Typography variant="body3" fontWeight={700}>
                      {userProfile.profile.company_name}
                    </Typography>
                  </Flex>
                  <Flex justifyContent="space-between">
                    <Typography variant="body3">E-mail</Typography>
                    <Typography variant="body3" fontWeight={700}>
                      {userProfile.email}
                    </Typography>
                  </Flex>
                  <Flex justifyContent="space-between">
                    <Typography variant="body3">Telefon</Typography>
                    <Typography variant="body3" fontWeight={700}>
                      numer telefonu.
                    </Typography>
                  </Flex>
                  <Flex justifyContent="space-between">
                    <Typography variant="body3">NIP</Typography>
                    <Typography variant="body3" fontWeight={700}>
                      {userProfile.profile.nip}
                    </Typography>
                  </Flex>
                  <Flex justifyContent="space-between">
                    <Typography variant="body3">Adres</Typography>
                    <Typography variant="body3" fontWeight={700}>
                      {userProfile.profile.company_street}{" "}
                      {userProfile.profile.company_street_number}/
                      {userProfile.profile.company_flat_number}
                    </Typography>
                  </Flex>
                  <Flex justifyContent="space-between">
                    <Typography variant="body3">Miejscowość</Typography>
                    <Typography variant="body3" fontWeight={700}>
                      {userProfile.profile.company_city}
                    </Typography>
                  </Flex>
                  <Flex justifyContent="space-between">
                    <Typography variant="body3">Link</Typography>
                    <Typography variant="body3" fontWeight={700}>
                      Link
                    </Typography>
                  </Flex>
                </StyledBox>
              </StyledFlex>
            </Wrapper>
          </BorderedCard>
        </Box>
      ) : (
        <>
          <Box px={2} pt={6}>
            <Typography variant="h2">
              Zostań partnerem i wystawiaj bony!
            </Typography>
            <Box mt={4}>
              <Button onClick={() => setIsOpen(true)} label="Dołącz" />
            </Box>
          </Box>
          <AddPartnerModal
            isOpen={isOpen}
            onCancelClick={() => setIsOpen(false)}
            handleConfirm={handleConfrim}
          />
        </>
      )}
      <MessageModal
        isOpen={displayMessageModal}
        onCancelClick={() => setDisplayMessageModal(false)}
        label="Dziękujemy za wypełnienie ankiety. 
        Nasz konsultant wkrótce się do Ciebie odezwie."
      />
    </>
  );
};
