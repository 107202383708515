import { theme } from "config/theme";
import { ButtonHTMLAttributes } from "react";
import styled from "styled-components";

interface IButton extends ButtonHTMLAttributes<HTMLButtonElement> {
  label: string;
  fullWidth?: boolean;
  variant?: "primary" | "secondary" | "third";
}
interface IStyledButton {
  variant: NonNullable<IButton["variant"]>;
  fullWidth: IButton["fullWidth"];
}

const { palette } = theme;

const textColorVariantsMap = {
  primary: palette.text.white,
  secondary: palette.primary.main,
  third: palette.primary.main,
};

const backgroundColorVariantsMap = {
  primary: palette.primary.main,
  secondary: palette.primary.secondary,
  third: palette.neutral.white,
};

const StyledButton = styled.button<IStyledButton>`
  ${({ variant, fullWidth }) => `
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${backgroundColorVariantsMap[variant]};
    color: ${textColorVariantsMap[variant]};
    padding: ${theme.space[3]}px ${theme.space[6]}px;
    border-radius: 25px;
    border: none;
    outline: none;
    cursor: pointer;
    ${
      fullWidth &&
      `
        width: 100%;
      `
    }
    ${
      variant === "third" &&
      `
      border: 2px solid ${theme.palette.primary.main};
    `
    }
  `}
`;

export const Button: React.FC<IButton> = ({
  label,
  fullWidth,
  variant = "primary",
  onClick,
}) => (
  <StyledButton fullWidth={fullWidth} variant={variant} onClick={onClick}>
    {label}
  </StyledButton>
);
