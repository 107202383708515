import { useAuthStateContext } from 'contexts/AuthContext';
import { USER_ROLE } from 'enums/role';

export const useProfileNavigate = () => {
  const { userProfile } = useAuthStateContext();

  const profileUserItems = [
    // {
    //   label: "Kupione bony",
    //   path: "/profile/boughtBons",
    // },
    {
      label: "Ustawienia",
      path: "/profile/settings",
    },
    {
      label: "Panel partnera",
      path: "/profile/partners",
    },
  ]

  const profilePartnerItems = [
    ...profileUserItems,
    {
      label: "Portfel",
      path: "/profile/wallet",
    },
    // {
    //   label: "Twoje Bony",
    //   path: "/profile/yoursBons",
    // },
    // {
    //   label: "Twoje kampanie",
    //   path: "/profile/campaigns",
    // }
  ]

  const navigationItems = () => {
    switch(userProfile?.role.kind) {
      case USER_ROLE.user:
        return profileUserItems;
      case USER_ROLE.fb_verified_user:
        return profileUserItems;
      case USER_ROLE.partner:
        return profilePartnerItems;
      default: 
        return profileUserItems;
    }
  }

  return { navigationItems };
}