import { fetchAPI } from "utilities/fetchAPI";
import {CAMPAIGNS_POST_LIKE_URL, CAMPAIGNS_URL, MY_CAMPAIGNS_URL} from 'constans/path';
import { IPagination } from "types/pagination";

export interface ICampaing {
  creator: ICreator;
  description: null;
  end_at: string;
  facebook_link: string;
  id: number;
  name: string;
  start_at: string;
  status: "active" | "inactive";
  tokens_amount: number;
  tokens_gratification: number;
  photo: string | null;
}
interface ICreator {
  birthdate: string;
  created_at: string;
  email: string;
  first_name: string;
  id: number;
  invitation_accepted_at: null;
  invitation_created_at: null;
  invitation_limit: null;
  invitation_sent_at: null;
  invitation_token: null;
  invitations_count: number;
  invited_by_id: number;
  invited_by_type: null
  last_name: string;
  updated_at: string;
}

export interface INewCampaing {
  name: string;
  start_at: string;
  end_at: string;
  facebook_link: string;
  tokens_amount: string;
  tokens_gratification: string;
  status: string;
}

interface IResponseCampaings {
  data: ICampaing[];
  pagination: IPagination;
}

export const fetchMyCampaingsApi = () => fetchAPI<IResponseCampaings>(MY_CAMPAIGNS_URL);
export const fetchCampaingsApi = (page: number, perPage: number) => fetchAPI<IResponseCampaings>(`${CAMPAIGNS_URL}?page=${page}&per_page=${perPage}`);

export const createCampaingsApi = (data: INewCampaing) => fetchAPI(CAMPAIGNS_URL, {
  method: "POST",
  body: JSON.stringify({campaign: data})
});

export const postCampaignLikeApi = (id: string|number) => fetchAPI(CAMPAIGNS_POST_LIKE_URL(id), { method: "POST" });
