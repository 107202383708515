import { ConnectFbUserService } from "services/auth";
import { toast } from "react-toastify";
import { useAuthDispatchContext } from "contexts/AuthContext";
import { fetchMyData } from "services/user";

export const useConnectUserFB = () => {
  const { setUserProfile } = useAuthDispatchContext();

  const connectFbAccount = () => {
    FB.getLoginStatus((response) => {
      if (response.status !== "connected") {
        FB.login(
          (loginResponse) => {
            ConnectFbUserService(loginResponse.authResponse.accessToken)
              .then((res) => {
                fetchMyData().then((data) => {
                  if(localStorage.getItem('likey-currentUser')) {
                    localStorage.removeItem('likey-currentUser');
                    setUserProfile(data);
                  }
                });
                toast.success("Konto zostało połączone z FB.");
              })
              .catch((error) => toast.error("Nie udało się połączyć z FB."));
          },
          { scope: "public_profile,email,pages_show_list,pages_read_engagement", return_scopes: true }
        );
      } else {
        FB.api("/me", (user: any) => {
          console.log("user", user);
        });
      }
    });
  };

  return { connectFbAccount };
};
