export const API_URL = process.env.REACT_APP_API_URL;
export const LOGIN_URL = `${API_URL}/login`;
export const LOGOUT_URL = `${API_URL}/logout`;
export const PASSWORD_URL = `${API_URL}/password`;
export const MY_PROFILE = `${API_URL}/users/me`;
export const USER_PROFILE_URL = `${API_URL}/profile`;
export const REGISTER_URL = `${API_URL}/signup`;

export const MY_CAMPAIGNS_URL = `${API_URL}/me/campaigns`;
export const CAMPAIGNS_URL = `${API_URL}/campaigns`;
export const CAMPAIGNS_POST_LIKE_URL = (id: string|number) => `${API_URL}/campaigns/${id}/likes`;

export const VOUCHERS_URL = `${API_URL}/vouchers`;
export const VOUCHER_BUY = `${API_URL}/vouchers/buy`;
export const MY_VOUCHERS_URL = `${API_URL}/me/vouchers`;
export const VOUCHER_CODES_URL = `${API_URL}/me/voucher_codes`;

export const CONNECT_FB_USER_URL = `${API_URL}/users/connect`;
export const CREATE_PAYMENTS_URL = `${API_URL}/payments`
export const GET_PAYMENT_URL = (id: string|number) => `${API_URL}/payments/${id}`
export const WALLET_EVENTS_URL = `${API_URL}/wallet_events`
