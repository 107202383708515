import * as yup from "yup";

export const partnerSchema = yup.object().shape({
  company_name: yup.string().required(),
  nip: yup.string().required(),
  company_street_number: yup.string().required(),
  company_flat_number: yup.string().required(),
  company_city: yup.string().required(),
  company_street: yup.string().required(),
  company_zip_code: yup.string().matches(/^[0-9]{2}(?:-[0-9]{3})?$/, 'Must be 5 or 9 digits').required()
}).required();