import React from "react";
import styled from "styled-components";

import { Typography } from "components/Typography";
import { Flex } from "components/Flex";
import { Label } from "components/Label";
import { system } from "styled-system";
import DatePicker from "react-date-picker";

interface IDatePicker {
  label?: string;
  placeholderText?: string;
  day: Date | undefined;
  error?: boolean;
  onChange(day: Date | undefined | string): void;
}

const StyledDayPicker = styled(DatePicker)<{ error?: boolean }>`
  box-sizing: border-box;
  height: 40px;
  font-family: "Open Sans";
  font-size: 14px;
  letter-spacing: 0;
  line-height: 20px;
  ${({ theme, error }) => `
    background-color: #EFEFEF;
    padding-left: ${theme.space[3]}px;
    .react-calendar__tile--active {
      background: ${theme.palette.primary.main} !important;
    }
    .react-date-picker__inputGroup__input {
      margin-top: ${theme.space[1]}px;
      height: 80%;
    }
    .react-calendar__month-view__days__day--weekend {
      color: black;
    }
    .react-date-picker__button {
      & svg {
        width: 14px;
      }
    }
    ${
      error &&
      `
      border: 1px solid ${theme.palette.text.error};
    `
    }
  `};
  .react-date-picker__wrapper {
    border: unset;
  }
  .react-date-picker__inputGroup__input:invalid {
    background: unset;
  }
  .react-calendar__tile--now {
    background: #e2e2e2;
  }

  ${system({
    borderColor: {
      property: "borderColor",
      scale: "colors",
    },
  })}
`;

export const DatePickerInput: React.FC<IDatePicker> = ({
  label,
  placeholderText,
  error,
  day,
  ...props
}) => {
  return (
    <Flex flexDirection="column">
      {label && <Label>{label}</Label>}
      <StyledDayPicker
        format="dd.MM.yyyy"
        onChange={props.onChange}
        value={day}
        locale="pl-PL"
        dayPlaceholder="dd"
        monthPlaceholder="mm"
        yearPlaceholder="yyyy"
        error={error}
      />
    </Flex>
  );
};
