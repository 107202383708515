import { Box } from "components/Box";
import { Card } from "components/Card";
// import { ConditionalWrapper } from "components/ConditionalWrapper";
import { Typography } from "components/Typography";
import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

export interface IMenu {
  items: Array<IMenuItem>;
  title?: string;
  card?: boolean;
  colorHighlight?: boolean;
}

export interface IMenuItem extends ITypography {
  label: string;
  path?: string;
  onClick?: () => any;
  current?: boolean;
  items?: Array<IMenuItem>;
  separated?: boolean;
}
interface ITypography {
  highlight?: boolean;
  hasItems?: boolean;
  colorHighlight?: boolean;
}

const StyledTypography = styled(Typography)<ITypography>`
  ${({ theme, highlight, hasItems, colorHighlight }) => `
    cursor: pointer;
    font-size: 14px;
    border-radius: 4px;

    ${
      !hasItems
        ? `
      &:hover {
        background-color: ${theme.palette.neutral.veryLight};
      }`
        : ""
    }

    ${
      highlight
        ? `
        font-weight: 600;
        color: ${
          colorHighlight
            ? theme.palette.primary.main
            : theme.palette.text.primary
        };
      `
        : ""
    }
  `}
`;

const StyledRouterLink = styled(Link)`
  ${({ theme }) => `
    height: 32px;
    color: inherit;
    text-decoration: none;
    display: flex;
    align-items: center;
    padding: 0 ${theme.space[4]}px;
  `}
`;

const StyledLink = styled.div`
  ${({ theme }) => `
    height: 32px;
    color: inherit;
    text-decoration: none;
    display: flex;
    align-items: center;
    padding: 0 ${theme.space[4]}px;
  `}
`;

const Submenu = styled.div`
  ${({ theme }) => `
    padding-left: ${theme.space[6]}px;
  `}
`;

const Line = styled.span`
  ${({ theme }) => `
		display: flex;
		margin: ${theme.space[1]}px auto;
    width: calc(100% - 32px);
		height: 1px;
	`};
`;

const hasCurrentSubItem = (items: Array<IMenuItem>): boolean =>
  !!items.find((item) => item.current);

const shouldBeHighlighted = (item: IMenuItem): boolean =>
  !!(item.current || (item.items && hasCurrentSubItem(item.items)));

export const Menu: React.FC<IMenu> = ({
  items,
  title,
  card = true,
  colorHighlight = true,
}) => (
  // <ConditionalWrapper
  //   condition={card}
  //   wrapper={(children: any) => <Card>{children}</Card>}
  // >
  <Box py={3} px={4}>
    {title ? (
      <Typography variant="h2" color="primary" mt={2} mb={2} pl={4}>
        {title}
      </Typography>
    ) : null}
    {items.map((item) => {
      const {
        label,
        path,
        onClick,
        current,
        items: subItems,
        separated,
      } = item;
      const hasItems = subItems !== undefined;
      const to = (Array.isArray(subItems) ? subItems[0].path : path) ?? "/";

      return (
        <div key={label}>
          {separated && <Line />}
          <StyledTypography
            highlight={shouldBeHighlighted(item)}
            colorHighlight={colorHighlight}
            variant="body"
            hasItems={hasItems}
          >
            {path ? (
              <StyledRouterLink to={to}>{label}</StyledRouterLink>
            ) : (
              <StyledLink onClick={onClick}>{label}</StyledLink>
            )}
          </StyledTypography>
          {current && subItems && (
            <Submenu>
              {subItems &&
                subItems.map((subItem) => (
                  <StyledTypography
                    key={subItem.label}
                    highlight={shouldBeHighlighted(subItem)}
                    colorHighlight={colorHighlight}
                    variant="body"
                  >
                    {subItem.path ? (
                      <StyledRouterLink to={subItem.path}>
                        {subItem.label}
                      </StyledRouterLink>
                    ) : (
                      <StyledLink onClick={subItem.onClick}>
                        {subItem.label}
                      </StyledLink>
                    )}
                  </StyledTypography>
                ))}
            </Submenu>
          )}
        </div>
      );
    })}
  </Box>
  // </ConditionalWrapper>
);
