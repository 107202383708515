import { useEffect } from "react";
import { Modal } from "components/Modal";
import { Box } from "components/Box";
import { Flex } from "components/Flex";
import { Input } from "components/Input";
import { Typography } from "components/Typography";
import { Button } from "components/Button";
import { IProfilePartner } from "services/user";
import { partnerSchema } from "schemas/partner";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import styled from "styled-components";

interface IBuyBonModal {
  isOpen: boolean;
  onCancelClick: () => void;
  handleConfirm: (data: IProfilePartner) => void;
}

const StyledInput = styled(Input)`
  width: 95%;
`;
const StyledHalfInput = styled(Input)`
  width: 48%;
`;

const defaultValues = {
  company_name: "",
  nip: "",
  company_city: "",
  company_street: "",
  company_street_number: "",
  company_flat_number: "",
  company_zip_code: "",
};

export const AddPartnerModal: React.FC<IBuyBonModal> = ({
  isOpen,
  onCancelClick,
  handleConfirm,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IProfilePartner>({
    defaultValues,
    resolver: yupResolver(partnerSchema),
  });

  useEffect(() => {
    console.log("errors", errors);
  }, [errors]);

  const onSubmit = (data: any) => {
    handleConfirm(data);
  };

  return (
    <Modal isOpen={isOpen} onCancelClick={onCancelClick}>
      <Box p={14}>
        <Box mb={15}>
          <Typography variant="h2">
            Wypełnij informacje i dodaj swoją firmię
          </Typography>
        </Box>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box mb={6}>
            <Flex>
              <StyledInput
                error={errors.company_name && errors.company_name.message}
                {...register("company_name")}
                label="Nazwa firmy"
              />
              <StyledInput
                error={errors.nip && errors.nip.message}
                {...register("nip")}
                label="NIP"
              />
            </Flex>
          </Box>

          <Box mb={6}>
            <Flex>
              <StyledInput
                error={errors.company_city && errors.company_city.message}
                {...register("company_city")}
                label="Miasto"
              />
              <StyledInput
                error={errors.company_street && errors.company_street.message}
                {...register("company_street")}
                label="Ulica"
              />
            </Flex>
          </Box>

          <Box mb={6}>
            <Flex>
              <StyledInput
                error={
                  errors.company_street_number &&
                  errors.company_street_number.message
                }
                {...register("company_street_number")}
                label="Nr budynku"
              />
              <StyledInput
                error={
                  errors.company_flat_number &&
                  errors.company_flat_number.message
                }
                {...register("company_flat_number")}
                label="Numer lokalu"
              />
            </Flex>
          </Box>

          <Flex justifyContent="flex-start">
            <StyledHalfInput
              error={errors.company_zip_code && errors.company_zip_code.message}
              {...register("company_zip_code")}
              label="Kod pocztowy"
            />
          </Flex>

          <Flex justifyContent="flex-end">
            <Box mt={3}>
              <Button label="Zapisz i dołącz" type="submit" />
            </Box>
          </Flex>
        </form>
      </Box>
    </Modal>
  );
};
