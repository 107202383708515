import { useHistory, useRouteMatch } from "react-router-dom";
import { useAuthStateContext } from 'contexts/AuthContext';
import { USER_ROLE } from 'enums/role';
import { LogoutUserService } from 'services/auth';

const links: Array<{ to: string; label: string; isPublic?: boolean }> = [
  {
    to: "/points",
    label: "akcje",
    isPublic: true,
  },
  {
    to: "/bons/buy",
    label: "Bony",
  },
  {
    to: "/campaigns/yours",
    label: "Kampanie",
  },
];

// const linksPartner: Array<{ to: string; label: string; isPublic?: boolean }> = [
//   ...links,
//   {
//     to: "/campaigns/yours",
//     label: "Twoje kampanie",
//   },
// ];

export const useNavbar = () => {
  const history = useHistory();
  // const { userProfile } = useAuthStateContext();

  // const navLinksPerRole = userProfile?.role.kind === USER_ROLE.partner ? linksPartner : links;

  const handleLogout = async () => {
    const token = localStorage.getItem('token');
    if(token) {
      localStorage.removeItem('token');
      localStorage.removeItem('likey-currentUser');
      history.push("/login");
      FB.logout();
      await LogoutUserService();
    }
  };

  const handleMyProfileClick = () => {
    history.push("/profile/info");
  };
  
  const navbarLinks: Array<{
    to: string;
    match: boolean;
    label: string;
  }> = links
    .map(({ label, to }) => {
      const match = !!useRouteMatch({
        path: to,
      });

      return {
        label,
        to,
        match,
      };
    });

  return { handleMyProfileClick, handleLogout, navbarLinks };
};