import {theme} from "config/theme";
import React from "react";
import styled, {css} from "styled-components";
import {space, SpaceProps, variant} from "styled-system";

export type TypographyType = {
  variant: keyof typeof theme.textStyles;
  children: React.ReactNode;
  color?: "primary" | "secondary";
  fontWeight?: 400 | 600 | 700;
  textAlign?: "center" | "right";
} & SpaceProps;

export const Typography = styled.div<TypographyType>(
  variant({
    scale: "textStyles",
  }),
  ({theme, color, fontWeight, textAlign}: any) => css`
    color: ${theme.palette.text.primary};
    ${fontWeight ? `font-weight: ${fontWeight};` : ""}
    ${textAlign ? `text-align: ${textAlign};` : ""}
  `,
  space
);

export const Ul = styled.ul<SpaceProps>(space);
export const Li = styled.li<TypographyType>(
  variant({
    scale: "textStyles",
  }),
  ({theme, color, fontWeight, textAlign}: any) => css`
    color: ${theme.palette.text.primary};
    ${fontWeight ? `font-weight: ${fontWeight};` : ""}
    ${textAlign ? `text-align: ${textAlign};` : ""}
    padding-left: 18px;
    position: relative;
    
    &:before {
      content: "●";
      left: 0;
      position: absolute;
    }
  `,
  space
);
