import { Modal } from "components/Modal";
import { Box } from "components/Box";
import { Flex } from "components/Flex";
import { CountCoin } from "components/CountCoin";
import { Typography } from "components/Typography";
import { Button } from "components/Button";
import { IBon } from "services/vouchers";
import styled from "styled-components";

interface IBuyBonModal {
  isOpen: boolean;
  onCancelClick: () => void;
  handleConfirm: () => void;
  item: IBon;
}

const WrapperBox = styled(Box)`
  position: relative;
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 60%;
    background-color: #f4f4f4;
    z-index: 1;
  }
`;
const StyledBox = styled(Box)`
  position: relative;
  z-index: 2;
`;
const DetailCard = styled.div`
  background-color: #fff;
  width: 100%;
`;
const WrapperTypography = styled(Box)`
  display: block;
  margin: 0 auto;
  width: 70%;
`;
const StyledTypography = styled(Typography)`
  text-align: center;
  color: #2a2a2a;
`;
const Image = styled.div`
  height: 212px;
  background-color: #e2e2e2;
`;

export const BuyBonModal: React.FC<IBuyBonModal> = ({
  isOpen,
  item,
  onCancelClick,
  handleConfirm,
}) => {
  return (
    <Modal isOpen={isOpen} onCancelClick={onCancelClick}>
      <Box pr={6}>
        <Flex>
          <WrapperBox>
            <StyledBox p={6}>
              <DetailCard>
                <Image />
                <WrapperTypography p={6}>
                  <Box mb={4}>
                    <StyledTypography variant="h3">
                      {item.voucher_price} zł
                    </StyledTypography>
                  </Box>
                  <Box mb={6}>
                    <StyledTypography variant="body3">
                      {item.name}
                    </StyledTypography>
                  </Box>
                </WrapperTypography>
              </DetailCard>
            </StyledBox>
          </WrapperBox>
          <Flex
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
          >
            <h1>Zakup bona</h1>
            <h3>Koszt</h3>
            <Box mb={4}>
              <CountCoin label={item.count} />
            </Box>
            <Button label="Kupuję" onClick={handleConfirm} />
          </Flex>
        </Flex>
      </Box>
    </Modal>
  );
};
