export const theme = {

  palette: {
    primary: {
      main: '#E36160',
      secondary: '#EFEFEF'
    },
    neutral: {
      white: '#fff',
      light: '#e8e8e8',
      medium: "#BEC9D5",
      active: '#EDF9EE',
      error: "#FBE6E5",
      warring: "#FEF6E0",
      blue: '#4159D5',
      facebook: '#408BEA'
    },
    text: {
      primary: '#2A2A2A',
      white: "#fff",
      active: "#56BC5A",
      error: "#E36160",
      warring: "#D3A418",
      grey: "#9D9D9D"
    }
  },
  textStyles: {
    body3: {
      fontSize: 22,
      lineHeight: "33px",
    },
    body2: {
      fontSize: 16,
      lineHeight: "20px",
    },
    body: {
      fontSize: 12,
      lineHeight: "20px",
    },
    h4: {
      fontSize: 17,
      lineHeight: "20px",
      fontWeight: 600,
    },
    h3: {
      fontSize: 20,
      lineHeight: "20px",
      fontWeight: 600,
    },
    h2: {
      fontSize: 24,
      lineHeight: "28px",
      fontWeight: 700,
    },
    h1: {
      fontSize: 32,
      lineHeight: "24px",
      fontWeight: 700,
    },
  },

  space: new Array(25).fill(null).map((value, index) => index * 4),
};
