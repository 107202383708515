import { Menu } from "components/Menu";
import { Router } from "components/Router";
import { useMenu } from "hooks/menu";
import { Col, Row } from "react-grid-system";
import { Route } from "types/route";
import { useAuthStateContext } from "contexts/AuthContext";
import { USER_ROLE } from "enums/role";
import styled from "styled-components";

const StyledCol = styled(Col)`
  background-color: #f7f7f7;
  height: 100vh;
`;

export const BonsView: React.FC<{
  routes: Array<Route>;
}> = ({ routes: subRoutes }) => {
  const { userProfile } = useAuthStateContext();

  const navigationBonList = [
    { label: "Kupuj bony", path: "/bons/buy" },
    { label: "Kupione bony", path: "/bons/boughtBons" },
  ];
  const partnerNavigationBonList = [
    { label: "Kupuj bony", path: "/bons/buy" },
    { label: "Kupione bony", path: "/bons/boughtBons" },
    { label: "Twoje bony", path: "/bons/yoursBons" },
    { label: "Nowy bon", path: "/bons/new" },
  ];

  const navigationBonItems =
    userProfile?.role.kind === USER_ROLE.partner
      ? partnerNavigationBonList
      : navigationBonList;

  const menuItems = useMenu(navigationBonItems);

  return (
    <Row>
      <StyledCol sm={2.5}>
        <Menu items={menuItems} />
      </StyledCol>
      <Col sm={9.5}>
        <Router routes={subRoutes} />
      </Col>
    </Row>
  );
};
