import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Button } from "./Button";

export interface IProps {
  onChange: (image: string) => void;
}

const PreviewImage = styled.img`
  width: 100%;
  height: 110px;
  border-radius: 5px;
  overflow: hidden;
  flex: 0 0 auto;
`;

export const FileUpload: React.FC<IProps> = ({ onChange }) => {
  const [image, setImage] = useState<File | null>(null);
  const [preview, setPreview] = React.useState<string>();

  const fileInputRef = React.useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    if (image) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreview(reader.result as string);
        onChange(reader.result as string);
      };
      reader.readAsDataURL(image);
    } else {
      setPreview("");
    }
  }, [image]);

  return (
    <>
      {preview ? (
        <PreviewImage
          src={preview}
          style={{
            objectFit: "cover",
            width: "100%",
            height: "100px",
          }}
        />
      ) : (
        <Button
          label="dodaj zdjęce"
          variant="primary"
          type="button"
          onClick={(event) => {
            event.preventDefault();
            fileInputRef.current?.click();
          }}
        />
      )}

      <input
        type="file"
        ref={fileInputRef}
        multiple
        accept="image/*"
        style={{ display: "none" }}
        onChange={(event) => {
          if (event.target.files) {
            const file = event.target.files[0];
            if (file) {
              setImage(file);
            } else {
              setImage(null);
            }
          }
        }}
      />
    </>
  );
};

FileUpload.displayName = "FileUpload";
