import React from "react";
import { ReactComponent as LikeIcon } from "icons/like.svg";
import { ButtonHTMLAttributes } from "react";
import styled from "styled-components";

const StyledButton = styled.button`
  ${({ theme }) => ` 
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #EFEFEF;
    color: #4159D5;
    padding: ${theme.space[3]}px ${theme.space[6]}px;
    border-radius: 18px;
    border: none;
    outline: none;
    cursor: pointer;
  `}
`;
const StyledLikeIcon = styled(LikeIcon)`
  width: 18px;
  margin-right: 10px;
`;

export const LikeButton: React.FC<ButtonHTMLAttributes<HTMLButtonElement>> = (
  props
) => (
  <StyledButton {...props}>
    <StyledLikeIcon /> Like
  </StyledButton>
);
