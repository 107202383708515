import { Menu } from "components/Menu";
import { Router } from "components/Router";
import { useMenu } from "hooks/menu";
import { useAuthStateContext } from "contexts/AuthContext";
import React from "react";
import { Col, Row } from "react-grid-system";
import { Route } from "types/route";
import styled from "styled-components";
import { USER_ROLE } from "enums/role";

const StyledCol = styled(Col)`
  background-color: #f7f7f7;
  height: 100vh;
`;

export const CampaignsView: React.FC<{
  routes: Array<Route>;
}> = ({ routes: subRoutes }) => {
  const { userProfile } = useAuthStateContext();

  const menuItems = useMenu([
    { label: "Twoje kampanie", path: "/campaigns/yours" },
  ]);

  const menuPartnerItems = useMenu([
    ...menuItems,
    { label: "Nowa kampania", path: "/campaigns/new" },
  ]);

  const navigationBonItems =
    userProfile?.role.kind === USER_ROLE.partner ||
    userProfile?.role.kind === USER_ROLE.fb_verified_user
      ? menuPartnerItems
      : menuItems;

  return (
    <>
      <Row>
        <StyledCol sm={2.5}>
          <Menu items={navigationBonItems} />
        </StyledCol>
        <Col sm={9.5}>
          <Router routes={subRoutes} />
        </Col>
      </Row>
    </>
  );
};
