import { BorderedCard } from "components/BorderedCard";
import { Typography } from "./Typography";
import { Box } from "./Box";
import { ButtonFb } from "components/ButtonFb";
import styled from "styled-components";
import { useConnectUserFB } from "hooks/connectUserFB";

const List = styled.ul`
  padding-left: 20px;
`;
const ListItem = styled.li`
  ${({ theme }) => `
    padding: ${theme.space[2]}px 0;
    list-style: disc;
  `}
`;
const Facebook = styled.span`
  color: #408bea;
`;

const Points = ["Promuj posty", "Twórz kampanie", "Zbieraj tokeny"];

export const ConnectFacebookCard = () => {
  const { connectFbAccount } = useConnectUserFB();

  return (
    <BorderedCard>
      <Box p={8}>
        <Typography variant="h3">
          Połącz konto z <Facebook>Facebookiem</Facebook>
        </Typography>
        <Box py={6}>
          <List>
            {Points.map((point) => (
              <ListItem key={point}>
                <Typography variant="body2">{point}</Typography>
              </ListItem>
            ))}
          </List>
        </Box>
        <ButtonFb onClick={connectFbAccount} />
      </Box>
    </BorderedCard>
  );
};
