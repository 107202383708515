import { useState } from "react";
import { Box } from "components/Box";
import { AddBonForm } from "components/AddBonForm";
import { CreateCreateVoucherModal } from "components/ConfrimCreateVoucherModal";
import {
  fetchMyVoucherService,
  createVoucherService,
  IBon,
  IVoucher,
} from "services/vouchers";
import { useLocation } from "react-router";
import queryString from "query-string";
import { toast } from "react-toastify";

export const NewBonView = () => {
  const location = useLocation();
  const parsedQuerystring = queryString.parse(location.search);
  const [displayConfirmModal, setDisplayConfirmModal] = useState(false);
  const [voucher, setVoucher] = useState<IVoucher | null>(null);
  const [displayForm, setDisplayForm] = useState(
    parsedQuerystring.new ? true : false
  );

  const openConfirmModal = (voucher: IVoucher) => {
    setDisplayConfirmModal(true);
  };

  const createVoucher = (voucher: IVoucher) => {
    createVoucherService(voucher)
      .then(() => {
        setDisplayForm(false);
        setDisplayConfirmModal(false);
        toast.success("Utworzyłeś nowy Voucher.");
      })
      .catch(() => toast.error("Voucher nie został dodany."));
  };

  return (
    <Box px={2} pt={6}>
      <AddBonForm openConfirmModal={openConfirmModal} />
      {voucher && (
        <CreateCreateVoucherModal
          voucher={voucher}
          isOpen={displayConfirmModal}
          createVoucher={createVoucher}
          onCancelClick={() => setDisplayConfirmModal(false)}
        />
      )}
    </Box>
  );
};
