import { Box } from "components/Box";
import { AddCampaignForm } from "components/AddCampaignForm";
import { createCampaingsApi, INewCampaing } from "services/campaign";
import { toast } from "react-toastify";
import {useAuthDispatchContext} from "../../contexts/AuthContext";

export const NewCampaignsView = () => {
  const {refreshUserProfile} = useAuthDispatchContext();

  const createCampaigns = (data: INewCampaing) => {
    createCampaingsApi(data)
      .then(() => {
        refreshUserProfile()
          .then(() => toast.success("Kampania została utworzona."))
      })
      .catch(() => toast.error("Nie udało się stworzyć kampanii"));
  };

  return (
    <Box p={6}>
      <AddCampaignForm createCampaigns={createCampaigns} />
    </Box>
  );
};
