import { useState, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router';
import queryString from 'query-string';

export const usePagination = () => {
  const itemsPerPage = 2;
  const location = useLocation();
  const history = useHistory();
  const qs = queryString.parse(location.search);
  const [currentPage, setCurrentPage] = useState(Number(qs.page) || 1)
  
  const handleClick = (number: number) => {
    setCurrentPage(number);
  };

  useEffect(() => {
    const query = { page: currentPage, perPage: itemsPerPage };
    history.push({ search: queryString.stringify(query) });
  }, [currentPage]);

  return { currentPage, handleClick, itemsPerPage }; 
}