import styled from "styled-components";
import { Flex } from "./Flex";
import { ReactComponent as LogoIcon } from "icons/logo.svg";
import { Link } from "react-router-dom";
import { Avatar } from "./Avatar";
import { Dropdown } from "components/Dropdown";
import { CountCoin } from "./CountCoin";
import { Button } from "./Button";
import { useHistory } from "react-router-dom";
import { Box } from "./Box";
import {useAuthStateContext} from "../contexts/AuthContext";

interface INavbar {
  links: Array<{
    to: string;
    match?: boolean;
    label: string;
  }>;
  profileDropdownItems: any[];
}

interface IListElement {
  active?: boolean;
  label: string;
  notification?: boolean;
}

interface IStyledLink {
  active?: boolean;
}

const Root = styled.div`
  ${({ theme }) => `
  background-color: #fff;
  position: fixed;
  top: 0;
  left: 0;
    width: 100%;
    box-shadow: 0 8px 24px -8px ${theme.palette.neutral.medium};
    margin-bottom: 10px;
    z-index: 10;
  `}
`;
const Wrapper = styled.div`
  padding: 21px 15px;
  display: block;
  margin: 0 auto;
  width: 740px;
  @media (min-width: 768px) {
    width: 960px;
  }
  @media (min-width: 992px) {
    width: 960px;
  }
  @media (min-width: 1200px) {
    width: 1140px;
  }
  @media (min-width: 1600px) {
    width: 1540px;
  }
`;
const StyledLogo = styled(LogoIcon)`
  width: auto;
  height: 50px;
`;
const Navigation = styled.ul`
  display: flex;
`;
const ListElement = styled.li<IListElement>`
  ${({ theme }) => `
    text-align: center;
    position: relative;
    cursor: pointer;
    margin-right: ${theme.space[10]}px;
    &:last-child {
      margin-right: 0;
    }
  `}
`;
const StyledLink = styled(Link)<IStyledLink>`
  ${({ theme, active }) => `
    display: inline-block;
    padding: ${theme.space[2]}px 0;
    margin-right: ${theme.space[2]}px;
    color: ${active ? theme.palette.primary.main : theme.palette.text.primary};
    text-decoration: none;
    text-transform: uppercase;
    font-weight: 600;
    padding: 0;
    line-height: 50px;
  `}
`;

export const Navbar: React.FC<INavbar> = ({ links, profileDropdownItems }) => {
  const authenticated = !!localStorage.getItem("token");
  const { userProfile } = useAuthStateContext();

  const history = useHistory();

  return (
    <Root>
      <Wrapper>
        <Flex justifyContent="space-between">
          {!authenticated && <StyledLogo />}
          {authenticated ? (
            <>
              <Flex justifyContent="flex-start">
                <Box mr={10}>
                  <StyledLogo />
                </Box>
                <Navigation>
                  {links.map(({ label, match, to }) => (
                    <ListElement key={to} label={label}>
                      <StyledLink to={to} active={match}>
                        {label}
                      </StyledLink>
                    </ListElement>
                  ))}
                </Navigation>
              </Flex>
              <Flex alignItems="center">
                <Flex alignItems="center">
                  {userProfile &&
                  <CountCoin
                    label={userProfile?.wallet?.tokens_amount ?? 0}
                    bordered
                    onClick={() => history.push("/profile/wallet")}
                  />
                  }
                  <Dropdown items={profileDropdownItems}>
                    <Avatar size="small" url={userProfile?.avatar} />
                  </Dropdown>
                </Flex>
              </Flex>
            </>
          ) : (
            <Flex alignItems="center">
              <Button
                label="Zaloguj się"
                variant="secondary"
                onClick={() => history.push("/login")}
              />
              <Box ml={3}>
                <Button
                  label="Zarejesruj się"
                  onClick={() => history.push("/register")}
                />
              </Box>
            </Flex>
          )}
        </Flex>
      </Wrapper>
    </Root>
  );
};
