import styled from "styled-components";
import { ReactComponent as OrocoinIcon } from "icons/orocoin.svg";
import { Typography } from "components/Typography";
import { Flex } from "./Flex";

interface ICountCoin {
  label: number;
  bordered?: boolean;
  radialSmall?: boolean;
  onClick?: () => void;
}

const Coins = styled(Flex)<{
  bordered?: boolean;
  actionClick?: boolean;
  radialSmall?: boolean;
}>`
  ${({ bordered, actionClick, radialSmall }) => `
  ${
    bordered &&
    `
      border: 1px solid #e8e8e8;
      padding: 10px 15px;
      padding-right: 8px;
      `
  }
      align-items: center;
      border-radius: ${radialSmall ? "18px" : "50px"};
      margin-right: 5px;
      svg {
        width: 25px;
        height: 25px;
        margin-left: 10px;
      }
      ${
        actionClick &&
        `
        cursor: pointer;
      `
      }
  `}
`;

export const CountCoin: React.FC<ICountCoin> = ({
  label,
  bordered,
  radialSmall,
  onClick,
}) => (
  <Coins
    alignItems="center"
    justifyContent="space-between"
    bordered={bordered}
    actionClick={!!onClick}
    onClick={onClick}
    radialSmall={radialSmall}
  >
    <Typography variant="body2">{label}</Typography>
    <OrocoinIcon />
  </Coins>
);
