import styled from "styled-components";
import {BorderedCard} from "../components/BorderedCard";
import {Li, Typography, Ul} from "../components/Typography";
import {Table} from "../components/Table";

const Title = styled(Typography).attrs({variant: 'h1'})``
const Container = styled(BorderedCard)`
  display: block;
  max-width: 900px;
  margin: 0 auto;
  padding: 36px 56px;

  & > ${Title} {
    line-height: 40px;
  }
  
  @media (max-width: 768px) {
    padding: 36px 16px;
  }
`;

export const PrivacyPolicyView = () => {

  return (
    <Container>
      <Title mb={4}>Zasady ochrony danych osobowych w ramach Platformy LikeY</Title>

      <Typography mb={4} fontWeight={600} variant="body2">1. Kto jest administratorem Twoich danych
        osobowych?</Typography>
      <Typography mb={4} variant="body2">Administratorem Twoich danych osobowych jest LikeY czyli COREPOINT Sp. z o.o. z
        siedzibą przy Al. Tadeusza Rejtana 23/1.2A, 35-326 Rzeszów wpisana do rejestru przedsiębiorców Krajowego
        Rejestru Sądowego w Sądzie Rejonowym w Rzeszowie, XII Wydział Gospodarczy pod nr KRS 0000740185, NIP 8133785702,
        kapitał zakładowy 5000 zł.
      </Typography>
      <Typography mb={4} variant="body2">Jeśli masz jakiekolwiek pytania związane z tym jak przetwarzamy Twoje dane,
        możesz skontaktować się bezpośrednio z powołanym przez nas specjalistą, który dba o bezpieczeństwo Twoich danych
        (Inspektor Ochrony Danych) pisząc pod adres biuro@likey.pl.
      </Typography>

      <Typography mb={4} fontWeight={600} variant="body2">2. Jakie dane i informacje o Tobie przetwarzamy?
      </Typography>

      <Typography mb={4} variant="body2">Jeżeli jesteś Użytkownikiem Platformy:
      </Typography>

      <Ul mb={4}>
        <Li variant="body2">Dane podane w formularzu rejestracyjnym
        </Li>
      </Ul>

      <Typography mb={4} variant="body2">dane rejestracyjne: pełne imię i nazwisko, datę urodzenia, adres
        korespondencyjny; ich podanie jest dobrowolne, ale bez nich podania nie będziesz mógł dokończyć rejestracji i w
        pełni korzystać z funkcjonalności Platformy LikeY, służą one bowiem do logowania na stronie www.likey.pl i w
        aplikacji mobilnej (data urodzenia i kod pocztowy) jak również są niezbędne do informowania Cię o ważnych
        zmianach w Platformie; dane fakultatywne: m.in. numer telefonu komórkowego, adres e-mail, płeć i wykonywany
        zawód.
      </Typography>

      <Ul mb={4}>
        <Li variant="body2">Dane o transakcjach dokonywanych na Platformie LikeY (w tym: liczba przyznanych Tokenów,
          liczba umorzonych Tokenów).
        </Li>
        <Li variant="body2">Informacje o sposobie nawigowania na naszej stronie internetowej i aplikacji mobilnej, takie
          jak oglądane akcji, bonów, odwiedzane sekcje oraz informacje o Twoich interakcjach z Platformą (np. w jakich
          kampaniach wziąłeś/wzięłaś udział).
        </Li>
      </Ul>

      <Typography mb={4} pl={4} variant="body2">Jeżeli jesteś reprezentantem naszego kontrahenta lub partnera
        biznesowego:
      </Typography>

      <Ul mb={4}>
        <Li variant="body2">Dane kontaktowe obejmujące: imię, nazwisko, nazwa firmy w której pracujesz, stanowisko,
          numer
          telefonu i adres e-mail.
        </Li>
      </Ul>

      <Typography mb={4} variant="body2">W przypadku, gdy powyższe dane osobowe nie zostały zebrane bezpośrednio od
        Ciebie, informujemy, że zostały przekazane przez naszego kontrahenta lub partnera biznesowego, którego jesteś
        reprezentantem lub pozyskane z publicznie dostępnych rejestrów lub innych publicznie dostępnych źródeł takich
        jak Internet.
      </Typography>


      <Typography mb={4} fontWeight={600} variant="body2">3. W jakim celu i na jakiej podstawie przetwarzamy Twoje dane
        osobowe?
      </Typography>
      <Typography mb={4} variant="body2">Będziemy przetwarzać Twoje dane osobowe w następujących celach:</Typography>
      <Typography mb={4} variant="body2">Jeżeli jesteś użytkownikiem Platformy:
      </Typography>

      <Table mb={4}>
        <tbody>
        <tr>
          <th>Cel</th>
          <th>Podstawa przetwarzania</th>
        </tr>
        <tr>
          <td>Prowadzenie Platformy LikeY – w tym naliczanie zebranych przez Ciebie tokeny, obsługa wymiany Tokenów na
            Bony oraz Kampanie i rozpatrywanie składanych przez Ciebie reklamacji
          </td>
          <td>Umowa jaką są Warunki Użytkowania Platformy LIKEY dostępny pod adresem www.LikeY.pl/regulamin</td>
        </tr>
        <tr>
          <td>Marketing - w tym przesyłanie lub wyświetlanie Ci informacji o towarach lub usługach obecnych i przyszłych
            Partnerów Platformy oraz samej Platformie w tym indywidualnie dobranych ofert
          </td>
          <td>Udzielona przez Ciebie zgoda</td>
        </tr>
        <tr>
          <td>Profilowanie, analizy rynkowe i statystyczne - w tym działania pomagające nam zrozumieć Twoje zwyczaje
            oraz oczekiwania jako klienta, uczynić ofertę Partnerów i sama Platforma LIKEY bardziej efektywnymi, a nasze
            oferty dostosować do Twoich potrzeb
          </td>
          <td>Nasz prawnie uzasadniony interes</td>
        </tr>
        </tbody>
      </Table>

      <Typography mb={4} variant="body2">Jeżeli jesteś reprezentantem naszego kontrahenta lub partnera biznesowego:
      </Typography>

      <Table mb={4}>
        <tbody>
        <tr>
          <th>Cel</th>
          <th>Podstawa przetwarzania</th>
        </tr>
        <tr>
          <td>
            Nawiązywanie, rozwijanie i prowadzenie współpracy z kontrahentem lub partnerem biznesowym którego jesteś
            reprezentantem
          </td>
          <td>Nasz prawnie uzasadniony interes</td>
        </tr>
        <tr>
          <td>
            Realizacja obowiązków raportowych w ramach grupy kapitałowej, której jesteśmy częścią
          </td>
          <td>
            Nasz prawnie uzasadniony interes
          </td>
        </tr>
        <tr>
          <td>
            Wystawianie i obsługa faktur z tytułu usług przez nas świadczonych
          </td>
          <td>
            Obowiązek prawny
          </td>
        </tr>
        </tbody>
      </Table>

      <Typography mb={4} fontWeight={600} variant="body2">4. Na czym polega profilowanie?
      </Typography>
      <Typography mb={4} variant="body2">W ramach tzw. profilowania będziemy dokonywać analizy i oceny informacji
        zgromadzonych o Tobie, próbując określić na ich podstawie jakie oferty i promocje mogą Cię najbardziej
        zainteresować. Wpływać to będzie bezpośrednio na to, jakie treści będą się wyświetlać w trakcie Twojej wizyty na
        stronie Platformy LIKEY, w aplikacji mobilnej, w newsletterach oraz innych stosowanych przez nas kanałach
        komunikacji.
      </Typography>


      <Typography mb={4} fontWeight={600} variant="body2">5. Odwołanie zgody
      </Typography>
      <Typography mb={4} variant="body2">W każdej chwili możesz odwołać udzieloną zgodę, w szczególności za
        pośrednictwem:</Typography>
      <Ul>
        <Li variant="body2">Ustawień panelu użytkownika na stronie www.LikeY.pl;</Li>
        <Li variant="body2" mb={4}>Biura Obsługi LIKEY pisząc na adres:, Al. Tadeusza Rejtana 23/1.2A, 35-326
          Rzeszów</Li>
      </Ul>
      <Typography mb={4} variant="body2">Odwołanie zgody pozostanie bez wpływu na zgodność z prawem przetwarzania,
        którego dokonaliśmy na podstawie zgody przed jej cofnięciem.
      </Typography>
      <Typography mb={4} variant="body2">Odpowiedni Partner Platformy, którego kartę zarejestrowałeś/aś, zostanie
        poinformowany przez nas o odwołaniu zgody.
      </Typography>
      <Typography mb={4} variant="body2">W przypadku odwołania zgody będziemy kontaktować się z Tobą jedynie wtedy, gdy
        przekazywane będą informacje niezbędne dla realizacji Platformy (np. zawiadomienie o stanie Twojego Konta).
      </Typography>


      <Typography mb={4} fontWeight={600} variant="body2">6. Przez jaki okres będziemy przetwarzać Twoje dane?
      </Typography>
      <Typography mb={4} variant="body2">Jeżeli jesteś Użytkownikiem Platformy:</Typography>
      <Typography mb={4} variant="body2">Będziemy przetwarzać Twoje dane nie dłużej niż przez okres Twojego użytkowania
        Platformy LIKEY oraz celem ustalenia, dochodzenia lub obrony ewentualnych roszczeń związanych z Twoim
        użytkowaniem Platformy przez okres przedawnienia tych roszczeń.
      </Typography>
      <Typography mb={4} variant="body2">Jeżeli jesteś reprezentantem naszego kontrahenta lub partnera biznesowego:
      </Typography>
      <Typography mb={4} variant="body2">Będziemy przetwarzać Twoje dane nie dłużej niż (i) do zakończenia naszej
        współpracy z kontrahentem lub partnerem biznesowym, którego jesteś reprezentantem, (ii) lub okres przez który
        byłeś uprawniony do kontaktu z nami, (iii) do czasu zakończenia Twojej współpracy z takim kontrahentem lub
        partnerem biznesowym (cokolwiek nastąpi wcześniej).
      </Typography>
      <Typography mb={4} variant="body2">Dodatkowo, w przypadku przetwarzania Twoich danych w celu obsługi faktur z
        tytułu usług przez nas świadczonych, będziemy przetwarzać je przez okres wymagany przepisami prawa.
      </Typography>


      <Typography mb={4} fontWeight={600} variant="body2">7. Przekazywanie danych
      </Typography>

      <Typography mb={4} variant="body2">Możemy udostępniać Twoje dane podmiotom trzecim takim jak organy administracji
        państwowej jeżeli zobowiążą nas do tego na podstawie przepisów prawa oraz na podstawie stosownej umowy, spółkom
        w ramach naszej grupy kapitałowej, naszym podwykonawcom wspierającym nas w realizacji kampanii marketingowych,
        serwisowaniu oprogramowania, dostarczaniu usług teleinformatycznych, a także firmom kurierskim i pocztowym.
      </Typography>
      <Typography mb={4} variant="body2">Zapewniamy należytą ochronę danych osobowych przetwarzanych przez naszych
        podwykonawców i ponosimy odpowiedzialność za ich działania.
      </Typography>
      <Typography mb={4} variant="body2">Wśród wyżej wymienionych podmiotów mogą być firmy przetwarzające Twoje dane
        poza Europejskim Obszarem Gospodarczego. Twoje dane będą w takim przypadku chronione w oparciu o standardowe
        klauzule ochrony danych zatwierdzone przez Komisję Europejską Decyzją Komisji Europejskiej z dnia 5 lutego 2010
        r. nr 2010/87/UE (Dz. Urz. UE L 39 s. 5 z 12 .02.2010).
      </Typography>
      <Typography mb={4} variant="body2">W każdym przypadku podwykonawcy będą mieć dostęp wyłącznie do tych danych,
        które są niezbędne do wypełnienia ich zadań w ramach Platformy i nie mogą wykorzystywać tych danych do żadnych
        innych celów.
      </Typography>


      <Typography mb={4} fontWeight={600} variant="body2">8. Jakie uprawnienia Ci przysługują?
      </Typography>

      <Typography mb={4} variant="body2">Przysługują Ci następujące uprawnienia, które możesz realizować kontaktując się
        z nami na adres biuro@LikeY.pl:
      </Typography>
      <Ul>
        <Li variant="body2">Prawo dostępu do danych – masz prawo do uzyskania informacji, czy przetwarzamy Twoje
          dane osobowe oraz do
          uzyskania dostępu tych danych, w tym otrzymania ich kopii.
        </Li>
        <Li variant="body2">Prawo do sprostowania danych – możesz żądać od nas sprostowania swoich danych
          osobowych, gdy są one
          niedokładne lub ich uzupełnienia, gdy są niekompletne. Możesz tego dokonać w zakładce „Edytuj swoje dane” w
          panelu użytkownika na stronie www.LikeY.pl.
        </Li>
        <Li variant="body2">Prawo do usunięcia danych - możesz zażądać od nas usunięcia swoich danych osobowych,
          kiedy, między innymi,
          nie są one już potrzebne do celów, do których zostały przez nas zebrane.
        </Li>
        <Li variant="body2">Prawo do ograniczenia przetwarzania - możesz zażądać ograniczenia przetwarzania
          danych w przypadkach
          określonych przepisami np. kiedy kwestionujesz ich prawidłowość lub legalność ich przetwarzania.
        </Li>
        <Li variant="body2">Prawo do przenoszenia danych – możesz otrzymać swoje dane osobowe w
          ustrukturyzowanym, powszechnie używanym
          formacie nadającym się do odczytu maszynowego i przekazać je innemu podmiotowi, wtedy gdy przetwarzanie danych
          opiera się na zgodzie lub umowie i odbywa się w sposób zautomatyzowany.
        </Li>
        <Li variant="body2">Prawo do sprzeciwu - możesz sprzeciwić się wobec przetwarzania przez nas Twoich
          danych osobowych w oparciu o
          uzasadniony interes , którymi się kierujemy, w tym wobec profilowania. W takim przypadku zaprzestaniemy
          przetwarzania Twoich danych w tym celu.
        </Li>
        <Li variant="body2" mb={4}>Prawo do wniesienia skargi – możesz wnieść skargę na to w jaki sposób przetwarzamy
          Twoje dane do Prezesa
          Urzędu Ochrony Danych Osobowych.
        </Li>
      </Ul>
      <Typography mb={4} variant="body2">Postępowanie w sprawie realizacji powyższych praw jest nieodpłatne. Możesz
        zostać poproszony/na o uiszczenie stosownej opłaty jedynie w przypadkach zgłoszenia żądania wydania drugiej i
        każdej kolejnej kopii danych (pierwsza kopia danych jest bezpłatna) oraz zgłaszania przez tę samą osobę żądań
        nadmiernych (np. niezwykle częstych) lub ewidentnie nieuzasadnionych.
      </Typography>
      <Typography mb={4} variant="body2">Powyższa opłata uwzględniać będzie jedynie koszty administracyjne związane z
        realizacją żądania.
      </Typography>
      <Typography mb={4} variant="body2">Zmiany Polityki Prywatności
      </Typography>
      <Typography mb={4} variant="body2">Niniejsza polityka jest na bieżąco weryfikowana i w razie potrzeby
        aktualizowana.
      </Typography>

      <Title mb={4}>Polityka Plików Cookies</Title>

      <Typography mb={4} variant="body2">Zarządzaj plikami cookies
      </Typography>

      <Typography mb={4} fontWeight={600} variant="body2">1. Czym są pliki cookies?
      </Typography>
      <Typography mb={4} variant="body2">Witryna internetowa www.LikeY.pl („Serwis”), jak wiele innych stron, używa tak
        zwanych ciasteczek („cookies”).
      </Typography>
      <Typography mb={4} variant="body2">Cookies to dane informatyczne, w szczególności niewielkie pliki tekstowe,
        zapisywane i przechowywane na urządzeniach za pośrednictwem, których korzystasz z Serwisu. Tylko wtedy, gdy
        ciasteczka są aktywne, możesz korzystać ze wszystkich funkcji Serwisu.
      </Typography>
      <Typography mb={4} variant="body2">Podmiotem zamieszczającym na Twoim urządzeniu końcowym Serwisu pliki cookies
        oraz uzyskującym do nich dostęp jest operator Serwisu – COREPOINT Sp. z o.o. z siedzibą przy Al. Tadeusza
        Rejtana 23/1.2A, 35-326 Rzeszów wpisana do rejestru przedsiębiorców Krajowego Rejestru Sądowego w Sądzie
        Rejonowym w Rzeszowie, XII Wydział Gospodarczy pod nr KRS 0000740185, NIP 8133785702, kapitał zakładowy 5000 zł.
        („LIKEY”).
      </Typography>

      <Typography mb={4} fontWeight={600} variant="body2">2. Rodzaje plików cookies
      </Typography>
      <Typography mb={4} variant="body2">W Serwisie stosowane są dwa rodzaje plików cookies:
      </Typography>
      <Ul>
        <Li variant="body2">cookies sesyjne (tymczasowe): są przechowywane na Twoim urządzeniu do momentu zakończenia
          sesji danej przeglądarki. Po zakończeniu sesji informacje są trwale usuwane z Twojego urządzenia. Sesja zwykle
          kończy się zamknięciem okna przeglądarki. Pliki cookies sesyjne pomagają nam przede wszystkim rozpoznać Twoją
          przeglądarkę w trakcie sesji i w ten sposób udostępniają Ci określone funkcje stron internetowych, na przykład
          koszyk podczas zmawiania nagród.
        </Li>
        <Li variant="body2" mb={4}>cookies stałe: nie są usuwane po zamknięciu przeglądarki i mogą być wykorzystane
          przez Serwis w przyszłości. Mają predefiniowaną datę ważności i są automatycznie usuwane po osiągnięciu tej
          daty. Okresy mogą wahać się od minut do kilku lat. Najdłuższy czas trwania to czas przechowywania cookies,
          przy pomocy których można sprzeciwić się przetwarzaniu niektórych danych (opt-out). Gwarantuje to, że
          ustawienia opt-out zdefiniowane w danej przeglądarce pozostają ważne tak długo, jak długo te pliki cookies nie
          są usunięte ręcznie.
        </Li>
      </Ul>

      <Typography mb={4} fontWeight={600} variant="body2">3. Funkcje ciasteczek i cel ich stosowania
      </Typography>
      <Typography mb={4} variant="body2">Stosowane pliki cookies pełnią następujące funkcje:
      </Typography>
      <Ul>
        <Li variant="body2">umożliwienie poprawnego działania i obsługi Serwisu (dostosowanie parametrów Serwisu do
          urządzenia, z którego korzystasz i odpowiednie wyświetlenie Serwisu,
        </Li>
        <Li variant="body2">utrzymanie i weryfikacja sesji użytkownika Serwisu po zalogowaniu, umożliwiające pełne
          korzystanie z oferowanych usług (weryfikacja źródeł przekierowań na strony internetowe Serwisu w celu
          poprawnej obsługi programów partnerskich i naliczanie Tokenów LIKEY),
        </Li>
        <Li variant="body2" mb={4}>usprawniające obsługę Serwisu (zapamiętanie lokalizacji Państwa urządzenia oraz
          tworzenie anonimowych statystyk oglądalności w celu dostarczania Państwu informacji i reklam dostosowanych do
          Państwa lokalizacji i potrzeb).
        </Li>
      </Ul>
      <Typography mb={4} variant="body2">W przypadku gdy, informacje zbierane przez pliki cookies będą zawierać
        informacje pozwalające na Twoją identyfikację, przetwarzanie tych danych będzie odbywać się na podstawie prawnie
        uzasadnionego interesu administratora, którym jest LIKEY. By dowiedzieć się więcej, sprawdź Zasady ochrony
        danych osobowych w ramach Platformy LIKEY .
      </Typography>
      <Typography mb={4} variant="body2">W Serwisie stosowane są trzy typy plików cookie ze względu na sposób ich
        przetwarzania:
      </Typography>


      <Typography mb={4} fontWeight={600} variant="body2">3.1. Pliki cookie niezbędne do funkcjonowania naszej strony
      </Typography>
      <Typography mb={4} variant="body2">Te pliki nie mogą być wyłączone w naszych systemach. Są one używane do
        nawigacji, przechowywania ustawień prywatności oraz naliczania Tokenów za dokonane transakcje w sieciach
        afiliacyjnych LIKEY:
      </Typography>
      <Table mb={4} unfixed>
        <tbody>
        <tr>
          <th>Rodzaj ciastek</th>
          <th>Opis</th>
          <th>Typ</th>
        </tr>
        <tr>
          <td>… .LikeY.pl</td>
          <td>Nazwa &ldquo;ciastka&ldquo;: UserID1 (wygasa po 365 dniach): cookieconsent_dismissed - sprawdzenie czy
            Użytkownik zamknął pasek informacyjny o &ldquo;ciastkach&ldquo;</td>
          <td>1</td>
        </tr>
        <tr>
          <td>LIKEY i LikeY.pl</td>
          <td>Nazwa &ldquo;ciastka&ldquo;: utmvmNkuFfXLZ, utmvxxxxxxxxxxxxxx, utmvc, utmvaNkuFfXLZ, UserID1 (wygasa po
            180 dniach): jako klient LIKEY jesteś rozpoznany (zalogowany), posiadasz osobisty Numer i przechodząc do
            jednego z uczestniczących w programie sklepów internetowych, aby tam zebrać Punkty online, wtedy
            przekazujemy Twój numer LIKEY za pośrednictwem jednej z sieci afiliacyjnych lub bezpośrednio do danego
            sklepu. Z powodów technicznych jest to możliwe tylko wtedy, gdy Twoja przeglądarka internetowa akceptuje
            pliki cookies (tzn. tylko wtedy możesz w ten sposób zbierać punkty online).
            <br/>
            <br/>
            Nazwa &ldquo;ciastka&ldquo;: tr_logout_cookie (wygasa po zamknięciu przeglądarki): system sprawdza
            prawidłowość zakończenie sesji logowania Użytkownika w Serwisie
            <br/>
            <br/>
            Nazwa &ldquo;ciastka&ldquo;: pb-marketing-widget-showed-sesion, pb-marketing-widget-minimize-session
            (wygasa po zamknięciu przeglądarki) : system sprawdza czy prawidłowo zostało wyświetlone i
            zminimalizowane pole na wpisanie Twojego numer LIKEY, po przejściu do sklepu jednej z sieci
            afiliacyjnych lub bezpośrednio w sklepie danej sieci.
            <br/>
            <br/>
            Nazwa &ldquo;ciastka&ldquo;: semi-affiliate-[branhId]-session (wygasa po zamknięciu przeglądarki) -
            system sprawdza czy pole na wpisanie Twojego numer LIKEY powinno zostać wyświetlone ze względu na
            aktywne przekierowania oraz rozwiązanie dla przeglądarek Internet Explorer
            <br/>
            <br/>
            Nazwa &ldquo;ciastka&ldquo;: semi-affiliate-[branhId] (wygasa po 30 dniach) - j.w.
            <br/>
            <br/>
            Nazwa &ldquo;ciastka&ldquo;: semi-afiliant-[branhId]-cookie-force-session (wygasa po zamknięciu
            przeglądarki): system wymusza wywołanie dla starszych wersji przeglądarek pól na wpisanie Twojego
            numeru LIKEY
          </td>
          <td>1</td>
        </tr>
        </tbody>
      </Table>


      <Typography mb={4} fontWeight={600} variant="body2">3.2 Statystyczne pliki cookie
      </Typography>
      <Typography mb={4} variant="body2">Te pliki cookie służą do ulepszania naszej strony internetowej. Pomagają nam
        one odpowiedzieć na pytania, które strony są najpopularniejsze, które są najmniej używane i jak odwiedzający
        poruszają się po stronie.
      </Typography>
      <Typography mb={4} variant="body2">Współpracujemy z usługodawcami, którzy pomagają nam w zarządzaniu naszymi
        ofertami w stałym ich udoskonalaniu. Korzystamy z usług tych przedsiębiorstw, które analizują sposób korzystania
        z naszej strony www oraz mierzą skuteczność naszej reklamy w Internecie i wykorzystanie naszej oferty
        internetowej używając w tym celu w szczególności plików web beacon oraz cookies. Korzystając z narzędzi
        śledzących, zbieramy w szczególności następujące informacje:
      </Typography>
      <Ul>
        <Li variant="body2">Jakie linki klikają użytkownicy online na innych stronach internetowych, aby uzyskać dostęp
          do Serwisu?
        </Li>
        <Li variant="body2">Które z naszych stron są odwiedzane, kiedy, jak często i w jakiej kolejności?
        </Li>
        <Li variant="body2">Jakich produktów poszukują nasi klienci?
        </Li>
        <Li variant="body2">Na jakie linki lub oferty klikają nasi klienci?
        </Li>
        <Li variant="body2">Wykorzystujemy te informacje do tworzenia statystyk, które pomagają nam zrozumieć
          następujące pytania:
        </Li>
        <Li variant="body2">Które strony są szczególnie atrakcyjne dla użytkowników naszych usług?
        </Li>
        <Li variant="body2">Które produkty najbardziej interesują naszych klientów?
        </Li>
        <Li variant="body2" mb={4}>Jakie oferty powinniśmy składać naszym klientom?
        </Li>
      </Ul>
      <Typography mb={4} variant="body2">Do zbierania i analizy informacji trackingowych wykorzystujemy następujące
        narzędzia:
      </Typography>

      <Table mb={4} unfixed>
        <tbody>
        <tr>
          <th>Rodzaj ciastek</th>
          <th>Opis</th>
          <th>Typ</th>
        </tr>
        <tr>
          <td><a rel="noreferrer" target="_blank"
                 href="https://experienceleague.adobe.com/docs/analytics/technotes/privacy-overview.html?lang=en#section_3DD2329B983849D3B8C24AEF7CD8DFB3">Adobe
            Analytics</a></td>
          <td>
            Nazwa &ldquo;ciastka&ldquo;: s_vi (wygasa po 730 dniach), omniture_optout, s_fid, s_cc
            <br/>
            <br/>
            Używa plików cookies w celu umożliwienia Serwisowi przeanalizowania sposobu korzystania z niego przez
            Ciebie, m.in. poprzez zbieranie informacji technicznych o przeglądarce, o czasie spędzonym na stronie,
            długości sesji, kliknięciach interakcjach. Jako narzędzie śledzenia w tym celu używamy „Adobe Analytics” od
            usługodawcy Adobe Systems Software Ireland Limited (4-6 Riverwalk, Citywest Business Campus, Dublin 24,
            Irlandia, zwanego dalej „Adobe”). O ile ten usługodawca otrzymuje dane, jest to zawsze pod naszą kontrolą.
            Adobe wykorzystuje informacje zebrane wyłącznie w naszym imieniu do celów określonych powyżej. Przetwarzanie
            danych odbywa się w centrum danych Adobe w Unii Europejskiej.
            <br/>
            Więcej informacji na temat przetwarzania danych użytkownika przez Adobe Analytics można znaleźć w <a
            target="_blank" rel="noreferrer"
            href="https://marketing.adobe.com/resources/help/en_US/reference/c_Privacy_Overview.html">polityce
            prywatności Adobe</a>.
            <br/>
            Możesz sprzeciwić się gromadzeniu danych przez Adobe Analytics. Kliknij link TUTAJ i wybierz opcję
            „wykluczenie”. Spowoduje to ustawienie w przeglądarce tak zwanego „opt-out cookie” dla Adobe Analytics. Ten
            plik cookie nie zawiera wartości odpowiednich do śledzenia. Pozwala jedynie rozpoznać sprzeczność, dzięki
            czemu nie będzie już więcej śledzenia. „Opt-out cookie” działa tylko w przeglądarce, w której kliknąłeś
            powyższy link.
          </td>
          <td>1</td>
        </tr>
        <tr>
          <td><a rel="noreferrer" target="_blank"
                 href="https://sarehub.com/wp-content/uploads/2018/06/Polityka_prywatnosci_SAREhub_RODO.pdf">SareHUB</a>
          </td>
          <td>
            Używa plików cookies w celu umożliwienia Serwisowi przeanalizowania sposobu korzystania z niego przez
            użytkowników, m.in. poprzez zbieranie informacji technicznych o przeglądarce, czasie spędzonym na stronie,
            długości sesji, subskrypcji web-pushy, kliknięciach i otwarciach.
            <br/>
            Jest to narzędzie dostawcy usług SARE S.A., z siedzibą w Rybniku, ul. Raciborska 35a, 44 – 200. O ile ten
            usługodawca otrzymuje dane, jest to zawsze pod naszą kontrolą. SARE S.A. wykorzystuje informacje zebrane
            wyłącznie w naszym imieniu do celów określonych powyżej. Przetwarzanie danych odbywa się w komputerowym
            centrum SARE S.A. w Unii Europejskiej.
            Więcej informacji o tym, jak SARE S.A. przetwarza dane użytkowników, można znaleźć w&nbsp;
            <a target="_blank"
               rel="noreferrer"
               href="https://sarehub.com/wp-content/uploads/2018/06/Polityka_prywatnosci_SAREhub_RODO.pdf">polityce
              prywatności SARE S.A.</a>
            <br/>
            Użytkownik może sprzeciwić się gromadzeniu danych przez SARE S.A. poprzez zmianę ustawień oprogramowania
            (przeglądarki internetowej) w zakresie umieszczanych plików cookies.
          </td>
          <td>1</td>
        </tr>
        <tr>
          <td><a rel="noreferrer" target="_blank"
                 href="https://help.hotjar.com/hc/en-us/articles/115011789248-Hotjar-Cookies">HotJar</a></td>
          <td>
            Nazwa &ldquo;ciastka&ldquo;: hjIncludedInPageviewSample, hjIncludedInSample (wygasa po 30 minutach) - plik
            cookie służy
            do informowania Hotjar o tym, czy odwiedzający sklep.LikeY.pl jest objęty próbkowaniem danych określonym
            przez limit odsłon Twojej witryny, m.in w celu informacji o zachowaniach Użytkownika.
            <br/>
            <br/>
            Nazwa &ldquo;ciastka&ldquo;: _hjTLDTest, hjid, hjAbsoluteSessionInProgress (wygasa po 30 minutach) - plik
            cookie jest
            używany przez HotJar do wykrywania pierwszej sesji odsłon użytkownika w Serwisie, m.in w celu informacji o
            zachowaniach Użytkownika.
          </td>
          <td>1</td>
        </tr>
        </tbody>
      </Table>

      <Typography mb={4} fontWeight={600} variant="body2">3.3. Pliki cookie związane z reklamami
      </Typography>
      <Typography mb={4} variant="body2">Te pliki cookie mogą być ustawiane przez naszych partnerów reklamowych za
        pośrednictwem naszej strony internetowej. Mogą one być wykorzystywane przez te firmy do budowania profilu
        zainteresowań użytkownika i wyświetlania odpowiednich reklam na innych stronach. Nie przechowują one
        bezpośrednio danych osobowych, lecz opierają się na jednoznacznej identyfikacji przeglądarki i sprzętu
        internetowego.
      </Typography>
      <Typography mb={4} variant="body2">W naszym serwisie wykorzystujemy następujące narzędzia reklamowe:
      </Typography>

      <Table mb={4} unfixed>
        <tbody>
        <tr>
          <th>Rodzaj ciastek</th>
          <th>Opis</th>
          <th>Typ</th>
        </tr>
        <tr>
          <td><a rel="noreferrer" target="_blank"
                 href="https://www.adition.com/en/privacy/">Adition</a></td>
          <td>
            Jako narzędzie śledzące w tym celu używamy „Adition” ADITION Technologies AG (Oststraße 55, D-40211
            Dusseldorf, Niemcy, zwanego dalej „Adition”). O ile ten usługodawca otrzymuje dane, jest to zawsze pod
            naszą kontrolą. Adition wykorzystuje informacje zebrane wyłącznie w naszym imieniu do powyższych celów.
            Przetwarzanie danych odbywa się w centrum komputerowym Adition w Unii Europejskiej.
            Aby uzyskać więcej informacji na temat przetwarzania danych użytkownika w Adition, w celu kierowania do
            użytkowników reklam zobacz Informacje o <a rel="noreferrer" target="_blank"
                                                       href="https://www.adition.com/en/privacy/">prywatności
            Adition</a>.
            <br/>
            <br/>
            Możesz sprzeciwić się gromadzeniu danych przez Adition ustawiając tak zwany „opt-out cookie” dla Adition w
            Twojej przeglądarce. Ten plik cookie nie zawiera wartości odpowiednich do śledzenia. Pozwala jedynie
            rozpoznać sprzeczność, dzięki czemu nie będzie już więcej śledzenia. „Opt-out cookie” działa tylko w
            przeglądarce, w której klikniesz link.&nbsp;
            <a rel="noreferrer" target="_blank"
               href="https://as.payback.pl/opt?m=optout&n=all&cbu=https://www.payback.pl/ochrona-danych/usuwanie-cookies">Wyłącz</a> lub&nbsp;
            <a rel="noreferrer" target="_blank"
               href="https://as.payback.pl/opt?m=optin&n=all&cbu=https://www.payback.pl/ochrona-danych/aktualizacja-cookies">Włącz</a> reklamę
            osób trzecich Adition Ads&nbsp;<a rel="noreferrer" target="_blank"
                                              href="https://www.adition.com/en/privacy/">https://www.adition.com/en/privacy/</a>
          </td>
          <td>1</td>
        </tr>
        <tr>
          <td><a rel="noreferrer" target="_blank"
                 href="https://support.google.com/google-ads/answer/2453998?hl=pl">Google Ads</a></td>
          <td>
            Nazwa &ldquo;ciastka&ldquo;: NID (wygasa po 183 dniach)
            <br/>
            <br/>
            Domena jest własnością firmy Google Inc. Chociaż Google jest przede wszystkim znana jako wyszukiwarka, firma
            zapewnia różnorodną gamę produktów i usług. Jednak głównym źródłem przychodów jest reklama. Google
            intensywnie śledzi użytkowników zarówno poprzez własne produkty i witryny, jak i liczne technologie osadzone
            w wielu milionach witryn internetowych na całym świecie. Wykorzystuje dane zebrane z większości tych usług
            do profilowania zainteresowań użytkowników sieci i sprzedaży powierzchni reklamowej organizacjom w oparciu o
            takie profile zainteresowań, a także do dopasowywania reklam do treści na stronach, na których pojawiają się
            reklamy klientów.
            <br/>
            <br/>
            Dlatego odwiedzając wcześniej nasz Serwis, aplikację, fanpage na portalu społecznościowym LIKEY możesz
            ponownie otrzymywać ukierunkowane reklamy na stronach i wyszukiwarkach podmiotów, z których usług
            korzystamy. Nie zbieramy i nie otrzymujemy żadnych informacji o tym, którzy użytkownicy faktycznie
            wyświetlają ukierunkowane reklamy. Nie możemy również zidentyfikować tutaj poszczególnych użytkowników.
            Wszystko w celu poprawienia skuteczności działań reklamowych. Szczegółowe informacje o powyższym systemie
            znajdziesz w polityce prywatności poszczególnych podmiotów, którzy świadczą nam te usługi -&nbsp;<a
            rel="noreferrer" target="_blank" href="https://support.google.com/google-ads/answer/2453998?hl=pl">Google
            Ads</a>
          </td>
          <td>3</td>
        </tr>
        <tr>
          <td><a rel="noreferrer" target="_blank"
                 href="https://support.google.com/google-ads/answer/2453998?hl=pl">YouTube</a></td>
          <td>
            Nazwa &ldquo;ciastek&ldquo;: GPS, VISITOR_INFO1_LIVE (wygasa po 180 dniach)
            <br/>
            <br/>
            Platforma należąca do Google do hostowania i udostępniania filmów. YouTube gromadzi dane użytkowników za
            pośrednictwem filmów osadzonych w witrynach internetowych, które są agregowane z danymi profilowymi z innych
            usług Google w celu wyświetlania ukierunkowanych reklam odwiedzającym w szerokim zakresie swoich i innych
            witryn internetowych.
            <br/>
            Dlatego odwiedzając wcześniej nasz Serwis, aplikację, fanpage na portalu społecznościowym LIKEY możesz
            ponownie otrzymywać ukierunkowane reklamy na stronach i wyszukiwarkach podmiotów, z których usług
            korzystamy. Nie zbieramy i nie otrzymujemy żadnych informacji o tym, którzy użytkownicy faktycznie
            wyświetlają ukierunkowane reklamy. Nie możemy również zidentyfikować tutaj poszczególnych użytkowników.
            Wszystko w celu poprawienia skuteczności działań reklamowych. Szczegółowe informacje o powyższym systemie
            znajdziesz w polityce prywatności poszczególnych podmiotów, którzy świadczą nam te usługi -&nbsp;<a
            rel="noreferrer" target="_blank" href="https://policies.google.com/privacy">YouTube</a>
          </td>
          <td>3</td>
        </tr>
        <tr>
          <td><a rel="noreferrer" target="_blank"
                 href="https://en-gb.facebook.com/business/learn/facebook-ads-pixel">Facebook Ads Pixel</a></td>
          <td>
            Nazwa &ldquo;ciastka&ldquo;: fr, fbp (wygasa po 90 dniach) - używana przez Facebooka do dostarczania serii
            produktów
            reklamowych, takich jak licytowanie w czasie rzeczywistym od reklamodawców zewnętrznych
            <br/>
            <br/>
            Nazwa &ldquo;ciastka&ldquo;: atn (wygasa po 730 dniach) - domena jest własnością Atlas Solutions, która z
            kolei jest
            własnością Facebooka. Atlas to internetowa firma reklamowa, której celem jest śledzenie ich aktywności w
            sieci. Od września 2014 r. Firma ponownie rozpoczęła działalność, będąc własnością Facebooka, obiecując
            możliwość dotarcia do znanych użytkowników i kierowania reklam na podstawie profili generowanych z
            wykorzystaniem danych użytkowników Facebooka.
            <br/>
            Dlatego jeżeli odwiedziłeś wcześniej nasz Serwis, aplikację, fanpage na portalu społecznościowym LIKEY
            możesz ponownie otrzymywać ukierunkowane reklamy na stronach i wyszukiwarkach podmiotów, z których usług
            korzystamy. Nie zbieramy i nie otrzymujemy żadnych informacji o tym, którzy użytkownicy faktycznie
            wyświetlają ukierunkowane reklamy. Nie możemy również zidentyfikować tutaj poszczególnych użytkowników.
            Wszystko w celu poprawienia skuteczności działań reklamowych. Szczegółowe informacje o powyższym systemie
            znajdziesz w polityce prywatności poszczególnych podmiotów, którzy świadczą nam te usługi -&nbsp;<a
            rel="noreferrer" target="_blank" href="https://en-gb.facebook.com/business/learn/facebook-ads-pixel">Facebook
            Ads Pixel</a>
          </td>
          <td>3</td>
        </tr>
        <tr>
          <td><a rel="noreferrer" target="_blank"
                 href="https://www.linkedin.com/legal/privacy-policy?_l=pl_PL">Linkedin</a></td>
          <td>
            Reklamy w formie remarketingu - jeżeli odwiedziłeś wcześniej nasz Serwis, aplikację, fanpage na portalu
            społecznościowym LIKEY możesz ponownie otrzymywać ukierunkowane reklamy na stronach i wyszukiwarkach
            podmiotów, z których usług korzystamy. Nie zbieramy i nie otrzymujemy żadnych informacji o tym, którzy
            użytkownicy faktycznie wyświetlają ukierunkowane reklamy. Nie możemy również zidentyfikować tutaj
            poszczególnych użytkowników. Wszystko w celu poprawienia skuteczności działań reklamowych. Szczegółowe
            informacje o powyższym systemie znajdziesz w polityce prywatności poszczególnych podmiotów, którzy świadczą
            nam te usługi - Linkedin
          </td>
          <td>3</td>
        </tr>
        </tbody>
      </Table>


      <Typography mb={4} fontWeight={600} variant="body2">
        4. Wyłączenie i ograniczenie dostępu plików cookies
      </Typography>
      <Typography mb={4} variant="body2">Możesz samodzielnie i w każdym czasie zmienić ustawienia dotyczące plików
        cookies, określając warunki ich przechowywania i uzyskiwania dostępu przez pliki cookies do Twojego urządzenia.
        Zmiany ustawień możesz dokonać za pomocą ustawień przeglądarki internetowej lub za pomocą konfiguracji usługi.
        Szczegółowe informacje o możliwości i sposobach obsługi plików cookies dostępne są w ustawieniach oprogramowania
        właściwe dla danej przeglądarki internetowej:
      </Typography>
      <Ul>
        <Li variant="body2"><a rel="noreferrer" target="_blank"
                               href="https://support.google.com/chrome/answer/95647?hl=pl">Google Chrome</a></Li>
        <Li variant="body2"><a rel="noreferrer" target="_blank"
                               href="https://support.mozilla.org/pl/kb/W%C5%82%C4%85czanie%20i%20wy%C5%82%C4%85czanie%20obs%C5%82ugi%20ciasteczek">Mozilla
          Firefox</a></Li>
        <Li variant="body2"><a rel="noreferrer" target="_blank"
                               href="https://support.microsoft.com/pl-pl/help/17442/windows-internet-explorer-delete-manage-cookies">Internet
          Explorer</a></Li>
        <Li variant="body2"><a rel="noreferrer" target="_blank" href="https://support.apple.com/pl-pl/HT201265">Apple
          Safari</a></Li>
        <Li variant="body2" mb={4}><a rel="noreferrer" target="_blank"
                                      href="https://help.opera.com/pl/latest/web-preferences/">Opera</a></Li>
      </Ul>

      <Typography mb={4} fontWeight={600} variant="body2">5. Zbieranie Tokenów online
      </Typography>
      <Typography mb={4} variant="body2">Jeżeli jesteś zalogowany w Serwisie, mając osobisty Numer LIKEY, a następnie
        przechodzisz do posta partnera na platformie społecznościowej, w celu zebrania Tokenów.
      </Typography>
      <Typography mb={4} variant="body2">Zebrane Punkty są księgowane na Twoim koncie. Z powodów technicznych jest to
        możliwe tylko wtedy, gdy Twoja przeglądarka internetowa akceptuje pliki cookies (tzn. tylko wtedy możesz w ten
        sposób zbierać tokeny online). Operatorzy sklepów internetowych zbierają i przetwarzają dane gromadzące się na
        ich stronach internetowych we własnym zakresie i na własną odpowiedzialność.
      </Typography>

      <Typography mb={4} fontWeight={600} variant="body2">6. Zapamiętywanie numerów LIKEY
      </Typography>
      <Typography mb={4} variant="body2">Aby nie musieć podawać osobistego numeru LIKEY za każdym razem, gdy odwiedzasz
        naszą stronę www, zapisujemy ten osobisty numer na stałe na Twoim komputerze. Za każdym razem, gdy odwiedzasz
        naszą witrynę, łącząc się z tego komputera, osobisty numer LIKEY jest rozpoznawany automatycznie. Następnie
        możesz, bez potrzeby ponownego wprowadzania numeru, przejść do sklepu internetowego i tam zbierać punkty online.
        Dla własnego bezpieczeństwa nie powinieneś/naś korzystać z tej funkcji na komputerach, z których mogą korzystać
        również osoby trzecie (np. w kafejkach internetowych). Z powodów technicznych funkcja ta działa tylko wtedy, gdy
        Twoja przeglądarka akceptuje stałe pliki cookies, ponieważ Twoje urządzenie jest rozpoznawany za pomocą takiego
        ciasteczka.
      </Typography>

      <Typography mb={4} fontWeight={600} variant="body2">7. Ograniczenie czasowe trwania sesji
      </Typography>
      <Typography mb={4} variant="body2">Przy każdym Twoim logowaniu w Serwisie zapisywany jest u nas w systemie numer
        identyfikacyjny sesji (numer sesji). Dzięki temu jesteś rozpoznawalny/na na każdej stronie. Jeżeli przez dłużej
        niż 15 minut nie wykonałeś żadnej czynności, dana sesja zostaje usunięta ze względów bezpieczeństwa. W razie
        potrzeby musisz ponownie się zalogować.
      </Typography>

      <Typography mb={4} fontWeight={600} variant="body2">8. Techniczne środki ochronne
      </Typography>
      <Typography mb={4} variant="body2"><strong>Captcha</strong> to kolejna funkcja bezpieczeństwa na naszym Serwisie,
        która zapobiega automatycznej rejestracji lub logowaniu hakerów. Oprócz standardowych formularzy do wypełniania
        oraz przed wejściem na wymagające ochrony elementy strony internetowej pojawiają się kolejne zapytania –
        obrazki.
      </Typography>
      <Typography mb={4} variant="body2">Obrazki te, tak zwane captchas (ciąg liczb i znaków) wykorzystuje się, aby
        wykluczyć możliwość korzystania z określonych formularzy lub stron internetowych.
      </Typography>
      <Typography mb={4} variant="body2">Ciąg liczb i znaków jest wybierany przez nasz system losowo, tak, aby Twoje
        dane mogły być przechowywane w możliwie najbezpieczniejszy sposób. Człowiek może rozpoznać te znaki bez
        większych problemów. Dla komputera nie stanowiłoby to również najmniejszego problemu, dlatego też znaki są
        zniekształcane, aby uniemożliwić manipulowanie daną usługą lub jej nadużywanie.
      </Typography>
      <Typography mb={4} variant="body2">W tym celu korzystamy z usługi &ldquo;reCAPTCHA&ldquo; firmy Google. Kiedy
        odwiedzasz naszą stronę internetową, przekazujemy informacje o Twoim dostępie do Google. W szczególności Google
        wykorzystuje następujące informacje w celu ustalenia, czy użytkownik jest człowiekiem czy komputerem:
      </Typography>
      <Ul>
        <Li variant="body2">Adres IP urządzenia końcowego
        </Li>
        <Li variant="body2">Adres strony internetowej, na której używana jest reCaptcha, w tym przypadku www.LikeY.pl,
        </Li>
        <Li variant="body2">Informacje o używanej przeglądarce (np. typ i wersja przeglądarki, rozdzielczość ekranu,
          język, zainstalowane wtyczki, czas i data),
        </Li>
        <Li variant="body2">Fakt posiadania konta Google i zalogowania na swoim koncie Google,
        </Li>
        <Li variant="body2">Twoje zachowanie podczas przeglądania danej strony internetowej,
        </Li>
        <Li variant="body2">Twoje zachowanie podczas wprowadzania danych (np. ruchy myszy na powierzchniach reCAPTCHA, w
          których trzeba zidentyfikować obrazy i dokonać wyboru),
        </Li>
        <Li variant="body2" mb={4}>Sprawdzenie historii przeglądania stron internetowych i zapisanych ciasteczek.
        </Li>
      </Ul>
      <Typography mb={4} variant="body2">Google obsługuje swoje serwery w USA, tj. w kraju trzecim spoza UE. Usługa
        reCAPTCHA firmy Google przesyła Twoje dane do tych serwerów w celu weryfikacji. Obecnie Komisja Europejska nie
        podjęła decyzji, zgodnie z którą Stany Zjednoczone zasadniczo zapewniają odpowiedni poziom ochrony. Google
        poddał się jednak ramom ochrony prywatności UE-USA, które zapewniają odpowiednie i uzasadnione zabezpieczenia.
        Więcej informacji można znaleźć&nbsp;<a
          href="https://www.google.com/recaptcha/intro/v3.html" target="_blank" rel="noreferrer">tutaj</a>.
      </Typography>
      <Typography mb={4} variant="body2">Więcej informacji na temat polityki prywatności Google można znaleźć&nbsp;<a
        href="https://policies.google.com/privacy" target="_blank" rel="noreferrer">tutaj</a> oraz warunków korzystania
        z usług Google.
      </Typography>

      <Typography mb={4} fontWeight={600} variant="body2">9. Przetwarzanie danych związane z użyciem klawiszy Plug-in
      </Typography>
      <Typography mb={4} variant="body2">W ramach Serwisu znajdują się klawisze stowarzyszone z różnymi portalami
        społecznościowymi, takie jak klawisz &ldquo;Lubię to!&ldquo; odnoszący się do portalu Facebook, oznaczone nazwą
        lub logo
        odpowiedniego portalu.
      </Typography>
      <Typography mb={4} variant="body2">Jeżeli wejdziesz na nasz Serwis zawierającą klawisz portalu społecznościowego,
        to Twoja wyszukiwarka automatycznie połączy się z odpowiednim portalem, a treść klawisza zostanie przeniesiona z
        serwerów tego portalu do Twojej przeglądarki, która umieści treść klawisza na naszym Serwisie. Dzięki temu
        operator portalu społecznościowego otrzyma informację, że odwiedziłeś/łaś nasz Serwis. Informacja ta zostanie
        przypisana do Twojego konta na portalu społecznościowym, jeśli jesteś do niego zalogowany/na.&nbsp;<a
          target="_blank" rel="noreferrer" href="https://www.facebook.com/business/gdpr">Radzimy zapoznać
          się ze szczegółowymi zasadami przetwarzania Twoich danych przez operatorów odpowiednich portali
          społecznościowych</a>.
      </Typography>
      <Typography mb={4} variant="body2">W wypadku, gdy nie życzysz sobie, by operator portalu społecznościowego zbierał
        Twoje dane poprzez nasz Serwis, musisz wylogować się ze swojego konta na tym portalu przed wejściem na nasz
        Serwis.
      </Typography>


      <Typography mb={4} fontWeight={600} variant="body2">10. Zmiany Polityki Plików Cookies
      </Typography>
      <Typography mb={4} variant="body2">Niniejsza polityka jest na bieżąco weryfikowana i w razie potrzeby
        aktualizowana.
      </Typography>

    </Container>
  );
};
