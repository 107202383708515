import { ChangeEvent, useState } from "react";
import { Box } from "components/Box";
import { BorderedCard } from "components/BorderedCard";
import { Button } from "components/Button";
import { Flex } from "components/Flex";
import { Typography } from "components/Typography";
import { Input } from "components/Input";
import { SendEmailForgottenPassword } from "services/auth";
import { toast } from "react-toastify";
import styled from "styled-components";

const StyledBorderedCard = styled(BorderedCard)`
  width: 600px;
  margin: 0 auto;
`;

export const ResetPasswordView = () => {
  const [emailValue, setEmailValue] = useState("");
  const [displaySuccessMessage, setDisplaySuccesMessage] = useState(false);

  const handleSendEmail = () => {
    SendEmailForgottenPassword(emailValue)
      .then(() => {
        // toast.success("Hasło zostało zmienione");
      })
      .finally(() => {
        setDisplaySuccesMessage(true);
      });
  };

  return (
    <StyledBorderedCard>
      <Box p={8}>
        {!displaySuccessMessage ? (
          <>
            <Typography variant="h1">Zmiana hasła</Typography>
            <Box mt={6} mb={8}>
              <Typography variant="body2">
                Wpisz maila, na który zostanie wysłany link do zmiany hasła.
              </Typography>
            </Box>
            <Box mb={8}>
              <Input
                label="E-mail"
                placeholder="np. jan.nowak@email.com"
                value={emailValue}
                onChange={(event: ChangeEvent<HTMLInputElement>) =>
                  setEmailValue(event.currentTarget.value)
                }
              />
            </Box>
            <Flex justifyContent="flex-end">
              <Button label="Wyślij" onClick={handleSendEmail} />
            </Flex>
          </>
        ) : (
          <>
            <Box mb={9}>
              <Typography variant="h1">
                Link do zmiany hasła został wysłany.
              </Typography>
            </Box>
            <Flex justifyContent="flex-end">
              <Button
                label="Zamknij"
                onClick={() => setDisplaySuccesMessage(false)}
              />
            </Flex>
          </>
        )}
      </Box>
    </StyledBorderedCard>
  );
};
