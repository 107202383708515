import React, { useContext, useState } from "react";
import { IUser } from "types/user";
import {fetchMyData} from "../services/user";
import {toast} from "react-toastify";

export interface IAuthContext {
  token: string | null;
  userProfile: IUser | null;
}

export interface IAuthDispatchContext {
  setToken: (token: string | null) => void;
  setUserProfile: (data: IUser) => void;
  refreshUserProfile: () => Promise<string|number|void>;
}

const AuthContext = React.createContext<IAuthContext | undefined>(undefined);

const AuthDispatchContext =
  React.createContext<IAuthDispatchContext | undefined>(undefined);

export const AuthContextProvider: React.FC = ({ children }) => {
  const [token, setToken] = useState<string | null>(
    localStorage.getItem("token") ?? null
  );
  const [userProfile, setUserProfile] = useState<IUser | null>(
    JSON.parse(`${localStorage.getItem("likey-currentUser")}`) ?? null
  );

  const refreshUserProfile = () => fetchMyData()
    .then(res => setUserProfile(res))
    .catch(() => toast.error('Nie udało się pobrać użytkownika.'))

  return (
    <AuthContext.Provider value={{ token, userProfile }}>
      <AuthDispatchContext.Provider
        value={{
          setToken: (newToken: string | null) => {
            if (newToken === null) {
              localStorage.removeItem("token");
            } else {
              localStorage.setItem("token", newToken);
            }
            setToken(token);
          },
          setUserProfile: (newUser: any | null) => {
            if (newUser === null) {
              localStorage.removeItem("likey-currentUser");
            } else {
              localStorage.setItem(
                "likey-currentUser",
                JSON.stringify(newUser)
              );
            }
            setUserProfile(newUser);
          },
          refreshUserProfile
        }}
      >
        {children}
      </AuthDispatchContext.Provider>
    </AuthContext.Provider>
  );
};

export const useAuthStateContext = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error(
      "useAuthContext must be inside a AuthContextProvider with a value"
    );
  }
  return context;
};

export const useAuthDispatchContext = () => {
  const context = useContext(AuthDispatchContext);
  if (!context) {
    throw new Error(
      "useAuthDispatchContext must be inside a AuthStateDispatchProvider with a value"
    );
  }
  return context;
};
