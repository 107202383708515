import { Card } from "components/Card";
import { ReactComponent as NextIcon } from "icons/next.svg";
import { ReactComponent as PreviousIcon } from "icons/previous.svg";
import React, { useState } from "react";
import styled from "styled-components";

interface IPagination {
  current: number;
  pages: number;
  itemsPerPage: number;
  onPageClick: (current: number) => void;
}

interface IPageNumber {
  active?: boolean;
}

const IconWrapper = styled.button`
  ${({ disabled }) => `
  background-color: transparent;
  border: none;
  height: 100%;
  width: 24px;
  padding: 0;
  margin: 0;
  cursor: pointer;
  ${
    disabled
      ? `
    opacity: 0.2;
  `
      : `
    opacity: 1;
  `
  }
  `}
`;

const StyledCard = styled(Card)`
  display: inline-flex;
  padding: 0 8px;
`;

const PageNumber = styled.div<IPageNumber>`
  ${({ theme, active }) => `
    color: ${active ? theme.palette.primary.main : theme.palette.text.primary};
    font-weight: ${active && "bold"};
  `};
  text-align: center;
  margin: 9px 0 11px;
  height: 20px;
  width: 40px;
  cursor: pointer;
`;

const pageNumberLimit = 1;

export const Pagination: React.FC<IPagination> = ({
  current,
  pages,
  itemsPerPage,
  onPageClick,
}) => {
  const [maxPageNumberLimit, setmaxPageNumberLimit] = useState(
    current === 1 ? 2 : current
  );
  const [minPageNumberLimit, setminPageNumberLimit] = useState(
    current === 1 ? 0 : current - 2
  );

  const renderPageNumber = () => {
    const data = [];
    for (let i = 1; i <= Math.ceil(pages / itemsPerPage); i += 1) {
      if (i < maxPageNumberLimit + 1 && i > minPageNumberLimit) {
        data.push(
          <PageNumber
            onClick={() => {
              onPageClick(i);
            }}
            key={i}
            active={i === current}
          >
            {i}
          </PageNumber>
        );
      }
    }
    return data;
  };

  return (
    <>
      {pages > 1 && (
        <StyledCard>
          <IconWrapper
            disabled={current - 1 < 1 ? true : false}
            onClick={() => {
              onPageClick(current - 1);

              if ((current - 1) % pageNumberLimit == 0) {
                setmaxPageNumberLimit(maxPageNumberLimit - pageNumberLimit);
                setminPageNumberLimit(minPageNumberLimit - pageNumberLimit);
              }
            }}
          >
            <PreviousIcon />
          </IconWrapper>
          {renderPageNumber()}
          <IconWrapper
            disabled={
              current + 1 > Math.ceil(pages / itemsPerPage) || pages === 1
                ? true
                : false
            }
            onClick={() => {
              onPageClick(current + 1);

              if (current + 1 > maxPageNumberLimit) {
                setmaxPageNumberLimit(maxPageNumberLimit + pageNumberLimit);
                setminPageNumberLimit(minPageNumberLimit + pageNumberLimit);
              }
            }}
          >
            <NextIcon />
          </IconWrapper>
        </StyledCard>
      )}
    </>
  );
};
