import {fetchAPI} from "utilities/fetchAPI";
import {CREATE_PAYMENTS_URL, GET_PAYMENT_URL, WALLET_EVENTS_URL} from 'constans/path';
import {IPagination} from "../types/pagination";

export interface IPayment {
  order_id: string;
  amount: number;
  status: string;
  url: string;
}

interface ICreatePaymentResponse {
  data: IPayment;
}

export const createPaymentsService = (payment_amount: number) => fetchAPI<ICreatePaymentResponse>(CREATE_PAYMENTS_URL, {
  method: 'POST',
  body: JSON.stringify({payment: {payment_amount}})
})

// Wallet Events
export interface IWalletEvent {
  amount: number,
  created_at: string,
  name: string,
  key: string
}

export interface IResponseWalletEvents {
  data: IWalletEvent[];
  pagination: IPagination;
}

export const fetchWalletEvents = (page: number, perPage: number) => fetchAPI<IResponseWalletEvents>(
  `${WALLET_EVENTS_URL}?page=${page}&per_page=${perPage}`,
  {
    method: 'GET',
  }
)

export interface IResponsePayment {
  data: {
    order_id: string
    amount: number
    status: string
    url: string
  }
}

export const fetchPayment = (id: string|number) => fetchAPI<IResponsePayment>(GET_PAYMENT_URL(id), { method: 'GET'})
