import { ReactComponent as UserIcon } from "icons/user.svg";
import React from "react";
import styled from "styled-components";

export interface AvatarI {
  size?: "default" | "small" | "large";
  url?: string;
}

interface RootI {
  size: AvatarI["size"];
}

interface ImageI {
  url?: AvatarI["url"];
}

const Root = styled.div<RootI>`
  ${({ size, theme }) => `
    display: inline-flex;
    align-items: center;
    justify-content: center;
    position: relative;
    height: ${size === "small" ? 47 : size === "large" ? 150 : 80}px;
    width: ${size === "small" ? 47 : size === "large" ? 150 : 80}px;
    flex: 0 0 auto;
    border-radius: 50%;
    color: ${theme.palette.neutral.dark};
    background-color: ${theme.palette.neutral.veryLight};
    overflow: hidden;
    border: 1px solid #dedee6;
  `}
`;

const Image = styled.div<ImageI>`
  ${({ url }) => `
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: transparent;
    ${url && `background-image: url('${url}');`}
    background-position: center;
    background-size: cover;
  `}
`;

export const Avatar: React.FC<AvatarI> = ({ size = "default", url }) => (
  <Root size={size}>
    <UserIcon />
    <Image url={url} />
  </Root>
);
