import {useEffect, useState} from "react";
import { Box } from "components/Box";
import { Flex } from "components/Flex";
import { Typography } from "components/Typography";
import { BorderedCard } from "components/BorderedCard";
import { CountCoin } from "components/CountCoin";
import { Button } from "components/Button";
import { RechargeAccountModal } from "components/RechargeAccountModal";
import {createPaymentsService, fetchPayment} from "services/payments";
import styled from "styled-components";
import { toast } from "react-toastify";
import { HistoryTransations } from "components/HistoryTransactions";
import {useAuthDispatchContext, useAuthStateContext} from "../../contexts/AuthContext";
import {useLocation, useParams} from "react-router";
import queryString from "query-string";

const Root = styled(Box)`
  background-color: #fff;
  position: relative;
`;
const WrapperButton = styled.div`
  position: absolute;
  right: 50px;
  top: 50%;
  transform: translateY(-50%);
`;
const Line = styled.div`
  ${({ theme }) => `
    width: 100%;
    height: 1px;
    margin: ${theme.space[4]}px 0;
    background-color: rgba(33, 33, 90, 0.15);
  `}
`;

export const WalletView = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { userProfile: user  } = useAuthStateContext();
  const { refreshUserProfile  } = useAuthDispatchContext();
  const location = useLocation()
  const qs = queryString.parse(location.search);

  const handleConfrimCreatePayment = (payment_amount: number) => {
    createPaymentsService(payment_amount)
      .then((response) => {
        console.log("response payment", response);
        refreshUserProfile()

        // solution for safari
        let a = document.createElement("a");
        document.body.appendChild(a);
        a.style.display = "none";
        a.href = response.data.url;
        a.target = '_blank'
        a.click();
        document.body.removeChild(a);

        // doesn't work for safari
        // const win = window.open(response.data.url, "_blank");
        // win?.focus();
        setIsOpen(false);
      })
      .catch(() => toast.error("Nie udało się doładować konta."));
  };

  useEffect(() => {
    refreshUserProfile()

    if (qs.id && 'string' == typeof qs.id) fetchPayment(qs.id).then(res => {
      if (res.data.status === 'pending') toast.success(`Twoja płatność jest w trakcie realizacji.`)
      if (res.data.status === 'completed') toast.success(`Twoja płatność powiodła się.`)
      if (res.data.status === 'failed') toast.success(`Twoja płatność nie powiodła się.`)
    })
      .catch(err => toast.error("Nie udało się pobrać dane o płatności."))
    console.log(qs)
  }, [])

  return (
    <>
      <Root p={6}>
        <Box>
          <BorderedCard>
            <Box p={4}>
              <Flex flexDirection="column">
                <Box mb={1}>
                  <Typography variant="body2">Twój portfel</Typography>
                </Box>
                <Line />
                <Box mb={1}>
                  {user &&
                  <CountCoin label={user?.wallet?.tokens_amount ?? 0} />
                  }
                </Box>
              </Flex>
              <WrapperButton>
                <Button
                  label="Doładuj swoje konto"
                  onClick={() => setIsOpen(true)}
                />
                {/* <Box mt={2}>
                  <Button label="Wypłać środki" variant="secondary" fullWidth />
                </Box> */}
              </WrapperButton>
            </Box>
          </BorderedCard>
        </Box>
      </Root>

      <HistoryTransations />

      <RechargeAccountModal
        isOpen={isOpen}
        onCancelClick={() => setIsOpen(false)}
        handleConfrimCreatePayment={handleConfrimCreatePayment}
      />
    </>
  );
};
