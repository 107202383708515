import { useState } from "react";
import { Button } from "components/Button";
import { Box } from "components/Box";
import { Flex } from "components/Flex";
import { Input } from "components/Input";
import { ReactComponent as OrocoinIcon } from "icons/orocoin.svg";
import { Modal } from "components/Modal";
import { Typography } from "components/Typography";
import styled from "styled-components";

interface IProps {
  isOpen: boolean;
  onCancelClick: () => void;
  handleConfrimCreatePayment: (amount: number) => void;
}

const Root = styled(Box)`
  width: 600px;
`;
const StyledInput = styled(Input)`
  width: 95%;
`;

export const RechargeAccountModal: React.FC<IProps> = ({
  isOpen,
  onCancelClick,
  handleConfrimCreatePayment,
}) => {
  const [amount, setAmount] = useState(0);

  const handleSubmit = () => {
    handleConfrimCreatePayment(amount);
  };

  return (
    <Modal isOpen={isOpen} onCancelClick={onCancelClick}>
      <Root p={14}>
        <Box mb={10}>
          <Typography variant="h2" textAlign="center">
            Doładuj swoje konto
          </Typography>
        </Box>
        <Flex alignItems="center">
          <Flex>
            <StyledInput
              type="number"
              value={amount}
              onChange={(event) => setAmount(Number(event.currentTarget.value))}
            />
            <OrocoinIcon />
          </Flex>
          <Box mx={2}>=</Box>
          <Flex alignItems="center">
            <StyledInput disabled value={amount} />
            <Typography variant="body2">PLN</Typography>
          </Flex>
        </Flex>
        <Box mt={10}>
          <Flex justifyContent="flex-end">
            <Button label="Wyślij" onClick={handleSubmit} />
          </Flex>
        </Box>
      </Root>
    </Modal>
  );
};
