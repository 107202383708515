import React from "react";
import { Box } from "components/Box";
import { Button } from "components/Button";
import { Flex } from "components/Flex";
import { Modal } from "components/Modal";
import { Typography } from "components/Typography";
import styled from "styled-components";

interface IProps {
  label: string;
  isOpen: boolean;
  onCancelClick: () => void;
}

const Wrapper = styled(Box)`
  width: 600px;
`;

export const MessageModal: React.FC<IProps> = ({
  label,
  isOpen,
  onCancelClick,
}) => {
  return (
    <Modal isOpen={isOpen} onCancelClick={onCancelClick}>
      <Wrapper p={14}>
        <Typography variant="h2">{label}</Typography>
        <Box mt={17}>
          <Flex justifyContent="flex-end">
            <Button label="Zamknij" onClick={onCancelClick} />
          </Flex>
        </Box>
      </Wrapper>
    </Modal>
  );
};
