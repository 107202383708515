import { Avatar } from "components/Avatar";
import { Box } from "components/Box";
import { Card } from "components/Card";
import { Flex } from "components/Flex";
import { Typography } from "components/Typography";
import styled from "styled-components";
import { BorderedCard } from "components/BorderedCard";
import { useAuthStateContext } from "contexts/AuthContext";
import { HistoryTransations } from "components/HistoryTransactions";

const Root = styled(Box)`
  background-color: #fff;
`;
const StyledBorderedCard = styled(BorderedCard)`
  ${({ theme }) => `
    padding: 0 ${theme.space[4]}px;
  `}
`;

export const MyProfileView = () => {
  const { userProfile } = useAuthStateContext();

  return (
    <Root p={6}>
      <Box>
        <StyledBorderedCard>
          <Avatar size="default" />
          <Box p={4}>
            <Flex flexDirection="column">
              <Box mb={1}>
                <Typography variant="body2">
                  {userProfile
                    ? `${userProfile.first_name} ${userProfile.last_name}`
                    : "Nieznany użytkownik"}
                </Typography>
              </Box>
              <Box mb={1}>
                <Typography variant="body2">
                  email: {userProfile?.email}
                </Typography>
              </Box>
              <Typography variant="body2">
                Data urodzenia: 26.08.1990
              </Typography>
            </Flex>
          </Box>
        </StyledBorderedCard>
      </Box>
      {/* <HistoryTransations /> */}
    </Root>
  );
};
