import { Flex } from "components/Flex";
import { Card } from "components/Card";
import styled from "styled-components";
import { Box } from "./Box";
import { LikeButton } from "./LikeButton";
import { CountCoin } from "components/CountCoin";
import { Typography } from "components/Typography";
import { ICampaing, postCampaignLikeApi } from "services/campaign";
import { useAuthDispatchContext } from "../contexts/AuthContext";
import noPhotoIcon from "icons/brak-zdjecia.png";

const StyledCard = styled(Card)`
  width: 100%;
  margin-bottom: 30px;
`;
const Image = styled.div<{ url: string | null }>`
  ${({ url }) => `
    height: 255px;
    background-image: url(${url});
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    ${
      !url &&
      `
      background-image: url(${noPhotoIcon});
      background-size: contain;
    `
    }
  `}
`;
const Hr = styled.div`
  height: 1px;
  background-color: #21215a;
  opacity: 0.15;
  margin: 0 15px;
`;
const FbText = styled(Typography)`
  color: #21215a;
  opacity: 0.5;
  text-align: center;
  padding: 16px 0 24px;
`;

export const CardPoint: React.FC<ICampaing> = (props) => {
  const { refreshUserProfile } = useAuthDispatchContext();

  console.log("props", props);

  const handlePostLike = (id: string | number) => {
    postCampaignLikeApi(id)
      .then((res) => {
        refreshUserProfile().then(() =>
          window.open(props.facebook_link, "_blank")?.focus()
        );
      })
      .catch((err) => console.log(err));
  };
  return (
    <StyledCard>
      <Image url={props.photo} />
      <Box p={6}>
        <Flex alignItems="center">
          <LikeButton onClick={() => handlePostLike(props.id)} />
          <Box ml={2}>
            <CountCoin
              label={props.tokens_gratification}
              radialSmall
              bordered
            />
          </Box>
        </Flex>
        <h3>{props.name}</h3>
        <p>{props.description}</p>
      </Box>
      <Hr />
      <FbText variant="body2">Facebook</FbText>
    </StyledCard>
  );
};
