import { InputHTMLAttributes } from "react";
import styled from "styled-components";

interface ILabel {
  error?: string;
  disabled?: InputHTMLAttributes<HTMLInputElement>["disabled"];
}

export const Label = styled.div<ILabel>`
  ${({ theme, error }) => `
    margin-left: 16px;
    margin-bottom: 4px;
    font-size: 12px;
    line-height: 16px;
    ${error && `color: ${theme.palette.accent.red}`};
  `}
`;
