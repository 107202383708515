import * as yup from "yup";

export const voucherSchema = yup.object().shape({
  name: yup.string().required(),
  voucher_price: yup.number().required().min(1),
  money_value: yup.number(),
  percent_value: yup.number(),
  count: yup.number().required(),
  website_address: yup.string().required(),
  start_at: yup.string().required(),
  end_at: yup.string().required(),
  image: yup.string().required(),
  voucher_codes: yup.string().required()
});