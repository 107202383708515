import { http } from "utilities/http";
import { LOGIN_URL, LOGOUT_URL, REGISTER_URL, CONNECT_FB_USER_URL, PASSWORD_URL } from "constans/path";
import { fetchAPI } from "utilities/fetchAPI";

interface IAuthUser {
  user: {
    email: string;
    password: string;
  };
}

interface IAuthUserResponse {
  data: {
    id: null;
    email: string;
    profile: null;
  };
  token: string;
}

export const AuthUserService = (user: IAuthUser) =>
  http<IAuthUserResponse>(LOGIN_URL, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify(user),
  });

export const LogoutUserService = () =>
  http(LOGOUT_URL, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  export interface IRegisterUserRequest {
    email: string;
    password: string;
    password_confirmation: string;
    first_name: string;
    last_name: string;
    birthdate: string;
  }

export const RegisterUserService = (user: IRegisterUserRequest) => http(REGISTER_URL, {
  method: "POST",
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
  body: JSON.stringify({user})
});

export const ConnectFbUserService = (accessToken: string) => fetchAPI(CONNECT_FB_USER_URL, {
  method: 'POST',
  body: JSON.stringify({authResponse: {accessToken}})
});

export const SendEmailForgottenPassword = (email: string) => fetchAPI(PASSWORD_URL, {
  method: "POST",
  body: JSON.stringify({user: {email}})
});

export interface IChangePasswordParams {
  reset_password_token: string;
  password: string;
  password_confirmation: string;
}

export const ChangePasswordServie = (data: IChangePasswordParams) => fetchAPI(PASSWORD_URL, {
  method: 'PATCH',
  body: JSON.stringify({user: data})
})
