import {Typography} from "../components/Typography";
import styled from "styled-components";
import {BorderedCard} from "../components/BorderedCard";
import {Link} from "react-router-dom";

const Title = styled(Typography).attrs({variant: 'h1'})``
const Container = styled(BorderedCard)`
  display: block;
  max-width: 900px;
  margin: 0 auto;
  padding: 36px 56px;
  word-break: break-word;
  
  & > ${Title} {
    line-height: 40px;
  }
  
  @media (max-width: 768px) {
    padding: 36px 16px;
  }
`;

export const RegulaminView = () => {
  return (
    <Container>
      <Title mb={4} variant="h1">Warunki użytkowania Platformy LIKEY</Title>

      <Typography mb={4} fontWeight={600} variant="body2">1. Wprowadzenie</Typography>
      <Typography mb={4} variant="body2">1.1 Niniejszy dokument („Warunki Użytkowania”) określają zasady funkcjonowania
        Systemu
        lojalnościowego LIKEY („Platforma LIKEY” lub „Platforma”) oraz warunki uczestnictwa w Programie, w tym zasady
        przyznawania uczestnikom Programu Punktów.</Typography>

      <Typography mb={4} fontWeight={600} variant="body2">2. Program LIKEY
      </Typography>
      <Typography mb={4} variant="body2">2.1 Platforma LIKEY jest zarządzana przez COREPOINT Sp. z o.o. z siedzibą przy
        Al. Tadeusza Rejtana 23/1.2A, 35-326 Rzeszów wpisana do rejestru przedsiębiorców Krajowego Rejestru Sądowego w
        Sądzie Rejonowym w Rzeszowie, XII Wydział Gospodarczy pod nr KRS 0000740185 przy współpracy przedsiębiorców, z
        którymi COREPOINT zawarł umowy o współpracy („Partnerzy Platformy”). Platforma jest prowadzona na terenie
        Polski. Informacje o Partnerach Platformy dostępne są na stronie internetowej Platformy
        (<Link to="/">www.LIKEY.pl</Link>).</Typography>
      <Typography mb={4} variant="body2">2.2 W ramach Platformy LIKEY za czynności określone przez Partnerów Platformy
        lub COREPOINT (w tym nabycie określonych bonów Partnerów Platformy w ilości wskazanej na stronie internetowej
        Platformy (www.LIKEY.pl)
      </Typography>

      <Typography mb={4} fontWeight={600} variant="body2">3. Użytkownicy</Typography>
      <Typography mb={4} variant="body2">3.1 Użytkownikiem Platformy może być każda osoba fizyczna, która ukończyła 16
        lat, posiada co najmniej ograniczoną zdolność do czynności prawnych, a jej miejsce zamieszkania znajduje się na
        terenie Polski, wykonująca czynności, o których mowa w punkcie 2.2, której został nadany numer LIKEY.
      </Typography>
      <Typography mb={4} variant="body2">3.2 Rejestracja na Platformie LIKEY oznacza przystąpienie do Platformy LIKEY.
        Przed rejestracją, powinni się Państwo, zapoznać z niniejszymi Warunkami Użytkowania. Przyznane Państwu Tokeny
        mogą Państwo wymieniać na Bony w każdej chwili.
      </Typography>
      <Typography mb={4} variant="body2">3.3 COREPOINT nie ma obowiązku informować Państwa o błędnie wypełnionym
        formularzu rejestracyjnym. Jednakże jeśli Państwa formularz rejestracyjny został wypełniony niekompletnie,
        COREPOINT może podjąć próbę skontaktowania się z Państwem celem uzupełnienia Państwa danych.
      </Typography>
      <Typography mb={4} variant="body2">3.4 Jednokrotne zgłoszenie do Platformy jest wystarczające; COREPOINT może nie
        przyjąć wielokrotnego zgłoszenia do Platformy tej samej osoby.
      </Typography>
      <Typography mb={4} variant="body2">3.5 Dane wprowadzone do formularza rejestracyjnego lub podane przy zawieraniu
        Umowy o Instrument Płatniczy LIKEY (w rozumieniu pkt. 4 poniżej) winny być przez Państwa aktualizowane. Zmiany
        danych, można dokonać mailowo na adres: biuro@likey.pl).
      </Typography>
      <Typography mb={4} variant="body2">3.6 Informacje dotyczące Platformy będą Państwu przekazywane za pomocą
        komunikatów na stronie www.LIKEY.pl, lub przesyłane na adres korespondencyjny, w formie elektronicznej (w tym:
        pocztą e-mail) lub na numer telefonu, który wskazali Państwo w formularzu zgłoszeniowym, lub w umowie o
        Instrument Płatniczy LIKEY.
      </Typography>

      <Typography mb={4} fontWeight={600} variant="body2">4. Instrument Płatniczy LIKEY
      </Typography>
      <Typography mb={4} variant="body2">4.1 Instrumentem Płatniczym LIKEY to identyfikator (w tym: karta lub pieniądz
        elektroniczny wydany w oparciu o odrębną umowę między użytkownikiem a podmiotem uprawnionym do wydawania takich
        instrumentów) zawierający numer LIKEY umożliwiający (miedzy innymi) dokonywanie zapłaty oraz uprawniający do
        korzystania z Platformy na zasadach określonych w niniejszych Warunkach Uczestnictwa („Instrument Płatniczy
        LIKEY”).
      </Typography>

      <Typography mb={4} fontWeight={600} variant="body2">5. Przyznawanie Punktów
      </Typography>
      <Typography mb={4} variant="body2">
        5.1 W związku z dokonywaniem czynności, o których mowa w pkt 2.2 niniejszych Warunków Użytkowania, zostaną
        Państwu przyznane Punkty. Informacja o liczbie przyznawanych Punktów jest dostępna na stronie internetowej
        www.LIKEY.pl w zakładkach „Portfel”.
      </Typography>
      <Typography mb={4} variant="body2">
        5.2 Tokeny nie podlegają wymianie na pieniądze. Punkty nie są pieniądzem elektronicznym.
      </Typography>
      <Typography mb={4} variant="body2">
        5.3 Partnerzy Platformy lub COREPOINT określają za jakie czynności są przyznawane Tokeny na zasadach określonych
        na stronie internetowej www.LIKEY.pl w zakładce „Akcje”
      </Typography>
      <Typography mb={4} variant="body2">
        5.4 Informacje o Partnerach Platformy, oraz inne informacje dotyczące Platformy uzyskać mogą Państwo za
        pośrednictwem Biura Obsługi LIKEY, na stronie internetowej www.LIKEY.pl, lub w aplikacji mobilnej Platformy. U
        Partnerów Platformy uzyskać można informacje związane z uczestnictwem w Programie oraz ogólne informacje
        dotyczące funkcjonowania Platformy.
      </Typography>
      <Typography mb={4} variant="body2">
        5.5 Aby uzyskać Tokeny musicie wykonać akcje określone przez w zakładce „Akcje”.
      </Typography>
      <Typography mb={4} variant="body2">
        5.6 Jeżeli nie dojdzie do wykonania akcji za które zostały Państwu przyznane Tokeny, np. gdy cofną Państwo
        wykonane akcje, COREPOINT i Partnerzy Platformy zastrzegają sobie prawo do cofnięcia przyznanych Państwu
        Punktów. To samo dotyczy przypadków nadużyć lub błędnego zaksięgowania transakcji.
      </Typography>

      <Typography mb={4} fontWeight={600} variant="body2">6. Portfel LIKEY
      </Typography>
      <Typography mb={4} variant="body2">
        6.1 Przyznane Państwu Tokeny są zapisywane w portfelu LIKEY na elektronicznym koncie prowadzonym i zarządzanym
        przez COREPOINT („Konto”) z zastrzeżeniem ust. 8.5 poniżej. W przypadku zarejestrowania w Platformie aktualny
        stan Konta mogą Państwo sprawdzić na stronie internetowej www.LIKEY.pl lub w aplikacji mobilnej LIKEY. COREPOINT
        może informować Państwa o stanie Państwa Konta pocztą lub w formie elektronicznej (np. e-mailem lub SMS-em).
      </Typography>
      <Typography mb={4} variant="body2">
        6.2 Zastrzeżenia dotyczące prawidłowości lub kompletności zawiadomienia o stanie Konta winny zostać przekazane w
        formie pisemnej do Biura Obsługi LIKEY (Al. Tadeusza Rejtana 23/1.2A, 35-326 Rzeszów) w ciągu trzydziestu dni po
        otrzymaniu w/w zawiadomienia. Nieprzekazanie zastrzeżeń we wskazanym terminie uznaje się za potwierdzenie stanu
        Konta. Do zastrzeżeń należy dołączyć odpowiednie dowody wykonania czynności określonych w pkt. 2.2.. Państwa
        zastrzeżenia zostaną niezwłocznie przekazane przez COREPOINT do właściwego Partnera Platformy celem weryfikacji
        ich zasadności. O rozpatrzeniu zgłoszonych zastrzeżeń zostaną Państwo poinformowani niezwłocznie po ich
        rozpoznaniu, nie później niż w ciągu czternastu dni od zgłoszenia zastrzeżeń.
      </Typography>
      <Typography mb={4} variant="body2">
        6.3 Partnerzy Platformy przyznający Tokeny ustanawiają dla przyznanych Punktów odpowiednie zabezpieczenia.
        Zabezpieczenia te znajdują się w zarządzie powierniczym COREPOINT.
      </Typography>

      <Typography mb={4} fontWeight={600} variant="body2">7. Wymiana Tokenów na Bony
      </Typography>


      <Typography mb={4} variant="body2"> 7.1 Przyznane Tokeny mogą Państwo: (i) wymienić na Bony, lub (ii) przekazać
        innym użytkownikom w formie kampanii.
      </Typography>
      <Typography mb={4} variant="body2"> 7.2 Według stanu z 1 stycznia 2016 roku maksymalna liczba Punktów, która może
        być jednorazowo wymieniona na Bon i jest wolna od podatku (na podstawie art. 21 ust. 1 pkt 68a ustawy „o podatku
        dochodowym od osób fizycznych”) odpowiada 200 Tokenów. W przypadku zmiany prawa w powyższym zakresie COREPOINT
        zastrzega możliwość zmiany liczby Punktów, która może być jednorazowo wymieniona na Nagrody. Powyższa zmiana nie
        stanowi zmiany niniejszych Warunków Użytkowania.
      </Typography>
      <Typography mb={4} variant="body2">7.3 W przypadku, gdy liczba Tokenów zebranych na Państwa Portfelu nie wystarcza
        na wymianę ich na wybraną przez Państwa Bon, COREPOINT, z uwzględnieniem kryteriów określonych na stronie
        internetowej www.LIKEY.pl, a także w regulaminie zamawiania i dostawy Nagród może umożliwić Państwu uzupełnienie
        różnicy między liczbą Punktów, które są zebrane na Państwa koncie a wartością wybranej Nagrody (wyrażoną w
        Punktach), przez dopłatę odpowiedniej sumy pieniężnej.</Typography>
      <Typography mb={4} variant="body2">7.4 Tokeny wymieniane są zgodnie z kolejnością ich uzyskania w Programie, tzn.
        Tokeny uzyskane przez Państwa wcześniej wymieniane są w pierwszej kolejności.</Typography>
      <Typography mb={4} variant="body2">7.6 Bony mogą stanowić przychód i podlegać opodatkowaniu.</Typography>
      <Typography mb={4} variant="body2">7.7 Przed wydaniem Bona COREPOINT lub Partnerzy Platformy mogą powtórnie
        sprawdzić stan Państwa Portfela. Wydanie Bona jest możliwe tylko w przypadku, gdy dostępna jest wystarczająca
        liczba Punktów, z zastrzeżeniem wyjątku przewidzianego w punkcie 8.2 powyżej.</Typography>
      <Typography mb={4} variant="body2">7.8 Zamówienie i dostawa Bona następuje zgodnie z regulaminem zamawiania i
        dostawy Bonów lub zgodnie z zasadami określonymi na stronie internetowej www.LIKEY.pl i jest realizowane
        elektronicznie i natychmiastowo.</Typography>
      <Typography mb={4} variant="body2">7.9 COREPOINT, jak też wybrani Partnerzy, dostarczają Użytkownikom Platformy
        Bony we własnym imieniu.</Typography>
      <Typography mb={4} variant="body2">7.10 Ze względu na wymogi bezpieczeństwa lub ograniczenia prawne niektóre Bony
        mogą być dostępne tylko dla osób pełnoletnich, posiadających pełną zdolność do czynności prawnych.</Typography>

      <Typography mb={4} fontWeight={600} variant="body2">8. Wygaśnięcie Punktów
      </Typography>
      <Typography mb={4} variant="body2">
        8.1 Jeśli nie postanowiono inaczej Tokeny LIKEY wygasają po upływie, trzydziestu sześciu miesięcy od ich
        przyznania , jeśli nie zostały w tym czasie wymienione na Bony zgodnie z zasadami Platformy. Ponadto, jeśli w
        okresie następujących po sobie dwunastu miesięcy w stosunku do danego Konta LIKEY nie zostaną Państwu przyznane
        Tokeny LIKEY ani nie wymienią Państwo Punktów LIKEY na Nagrody, Państwa Konto LIKEY może zostać zamknięte, a
        znajdujące się na nim Tokeny wygasną.
      </Typography>

      <Typography mb={4} fontWeight={600} variant="body2">9. Ustanie Użytkowania w Programie
      </Typography>
      <Typography mb={4} variant="body2">9.1 Z użytkowania Platformy mogą Państwo zrezygnować w każdej chwili składając
        oświadczenie na piśmie przesłane na adres Biura Obsługi LIKEY (Al. Tadeusza Rejtana 23/1.2A, 35-326
        Rzeszów).</Typography>
      <Typography mb={4} variant="body2">9.2 Z ważnych powodów COREPOINT może zawiesić, lub wypowiedzieć Państwu
        użytkowania z Platformy. Za ważne powody, o których mowa w zdaniu poprzednim uznaje się: nadużycie lub inne
        naruszenia Warunków Użytkowania. Wypowiedzenie wymaga formy pisemnej ze wskazaniem przyczyny wypowiedzenia
        Użytkowania z Platformy. Możemy zdecydować o przyjęciu reżimów właściwych dla “instytucji obowiązanej” na mocy
        ustawy „o przeciwdziałaniu praniu pieniędzy oraz finansowaniu terroryzmu” lub innych przepisów prawa
        międzynarodowego (także przyjętych przez ONZ) w tym określonych przez Biuro ds. Kontroli Aktywów Zagranicznych
        Departamentu Skarbu Stanów Zjednoczonych. Jeśli Państwa dane zostaną opublikowane na liście „Specially
        Designated Nationals and Blocked Persons list” dostępnej pod adresem http://sdnsearch.ofac.treas.gov/ Państwa
        konta LIKEY, zostanie zamknięte bez uprzedniego powiadomienia. Dodatkowe informacje o stosowaniu sankcji
        międzynarodowych są dostępne nas stronie MSZ: https://www.msz.gov.pl/pl/c/MOBILE/polityka_zagraniczna
        /polityka_bezpieczenstwa/sankcje_miedzynarodowe/. Możecie Państwo odwołać się od wypowiedzenia w ciągu
        czternastu dni od otrzymania zawiadomienia o wypowiedzeniu, wysyłając pisemne oświadczenie zawierające
        uzasadnienie na adres Biura Obsługi LIKEY (Al. Tadeusza Rejtana 23/1.2A, 35-326 Rzeszów). COREPOINT udzieli
        Państwu odpowiedzi w ciągu trzydziestu dni od dnia wpływu Państwa odwołania.</Typography>
      <Typography mb={4} variant="body2"> 9.3 Zakończenie Użytkowania z Platformy prowadzi do utraty zebranych Punktów.
        Uczestnik, który zamierza zrezygnować z Platformy, powinien uprzednio wykorzystać zgromadzone Tokeny.
      </Typography>
      <Typography mb={4} variant="body2"> 9.4 O ile umowa z wystawcą Instrumentu Płatniczego nie określa innych skutków,
        zakończenie Użytkowania w Programie nie ma wpływu na obowiązywanie umowy o Instrument Płatniczy z funkcjami
        LIKEY zawartej z podmiotem o którym mowa w pkt. 5 (powyżej).
      </Typography>

      <Typography mb={4} fontWeight={600} variant="body2">10. Zmiana Warunków Użytkowania
      </Typography>
      <Typography mb={4} variant="body2">11.1 Niniejsze Warunki Użytkowania mogą zostać zmienione lub uzupełnione przez
        COREPOINT z przyczyn takich jak konieczność zapewnienia bezpiecznego i zgodnego z przepisami prawa
        funkcjonowania Platformy, a w szczególności zapobiegania nadużyciom. O zmianach zostaną Państwo powiadomieni
        przez publiczne ogłoszenie na stronie internetowej www.LIKEY.pl z trzydziestodniowym wyprzedzeniem. Zmianę uważa
        się za potwierdzoną, jeśli w ciągu miesiąca od w/w zawiadomienia nie wypowiedzą Państwo Użytkowania w Programie
        lub gdy przed upływem tego terminu użyją Państwo numeru LIKEY w sposób przewidziany w niniejszych Warunkach
        Użytkowania.
      </Typography>

      <Typography mb={4} fontWeight={600} variant="body2">11. Zakończenie Platformy LIKEY
      </Typography>
      <Typography mb={4} variant="body2">11.1 COREPOINT może zawiesić lub zakończyć działalność Platformy. O takiej
        decyzji zostaną Państwo niezwłocznie poinformowani, przynajmniej trzydzieści dni przed zawieszeniem lub
        zakończeniem Platformy. Informacja ta zostanie przekazana także na stronie internetowej www.LIKEY.pl.
      </Typography>
      <Typography mb={4} variant="body2">11.2 Gdy Platforma zostanie zakończona, mogą Państwo wymienić swoje Tokeny na
        Bony w terminie określonym w zawiadomieniu o zakończeniu Platformy. Po upływie tego terminu wymiana Punktów nie
        będzie możliwa.
      </Typography>

      <Typography mb={4} fontWeight={600} variant="body2">12. Reklamacje
      </Typography>
      <Typography mb={4} variant="body2">12.1 Reklamacje lub informacje o nieprawidłowościach dotyczących Platformy LIKEY
        prosimy zgłaszać do Biura Obsługi LIKEY – na piśmie na adres: Al. Tadeusza Rejtana 23/1.2A, 35-326 Rzeszów lub
        adres mailowy biuro@likey.pl. COREPOINT rozpatrzy reklamację niezwłocznie, nie później niż w ciągu czternastu
        dni od daty ich otrzymania. Mogą Państwo skorzystać z pozasądowych sposobów rozpatrywania reklamacji i
        dochodzenia roszczeń zgodnie z zasadami określonymi na stronie www.uokik.gov.pl w zakładce „Rozstrzyganie sporów
        konsumenckich”(lub na stronie internetowej innego właściwego organu do spraw ochrony konsumentów).
      </Typography>
      <Typography mb={4} variant="body2">Dla wszystkich praw i obowiązków, jakie wynikną z niniejszych Warunków
        Użytkowania, właściwe jest prawo polskie.
      </Typography>

    </Container>
  );
};
