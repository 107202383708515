import React from "react";
import { theme } from "config/theme";
import styled from "styled-components";
import { Typography } from "./Typography";

interface IInfoBox {
  variant: "active" | "inactive" | "error" | "warring";
  label?: string;
}
interface IWrapper {
  variant: IInfoBox["variant"];
}
interface ILabel {
  variant: IInfoBox["variant"];
}

const variantBackgroundMap = {
  active: theme.palette.neutral.active,
  inactive: theme.palette.neutral.error,
  warring: theme.palette.neutral.warring,
  error: theme.palette.neutral.error,
};
const variantTypographyMap = {
  active: theme.palette.text.active,
  inactive: theme.palette.text.error,
  warring: theme.palette.text.warring,
  error: theme.palette.text.error,
};

const Wrraper = styled.div<IWrapper>`
  ${({ theme, variant }) => `
    background-color: ${variantBackgroundMap[variant]};
    padding: ${theme.space[3]}px;
    border-radius: 5px;
    `}
`;
const Label = styled.h3<ILabel>`
  ${({ variant }) => `
  text-align: center;
  margin: 0;
  color: ${variantTypographyMap[variant]};
  `}
`;

export const InfoBox: React.FC<IInfoBox> = ({ variant, label }) => {
  const renderContent = (
    variant: "active" | "inactive" | "error" | "warring",
    label?: string
  ) => {
    switch (variant) {
      case "active":
        return <Label variant={variant}>{label || "Aktywny"}</Label>;
      case "inactive":
        return <Label variant={variant}>{label || "Nieaktywny"}</Label>;
      case "error":
        return <Label variant={variant}>{label || "Zakończony"}</Label>;
      case "warring":
        return <Label variant={variant}>{label || "Zaplanowane"}</Label>;
    }
  };

  return <Wrraper variant={variant}>{renderContent(variant, label)}</Wrraper>;
};
