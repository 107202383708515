import React, { ButtonHTMLAttributes } from "react";
import styled from "styled-components";
import { Box } from "./Box";
import { Typography } from "./Typography";

interface IProps extends ButtonHTMLAttributes<HTMLButtonElement> {}

const Root = styled.button`
  ${({ theme }) => `
    background-color: ${theme.palette.neutral.facebook};
    border-radius: 9px;
    border: none;
    cursor: pointer;
  `}
`;
const StyledTypography = styled(Typography)`
  ${({ theme }) => `
    font-size: 19px;
    color: ${theme.palette.text.white};
    white-space: nowrap;
  `}
`;

export const ButtonFb: React.FC<IProps> = ({ onClick, ...props }) => (
  <Root onClick={onClick}>
    <Box p={3}>
      <StyledTypography variant="h3" textAlign="center">
        Połącz konto z Facebookiem
      </StyledTypography>
    </Box>
  </Root>
);
