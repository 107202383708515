import {BonCard} from "components/BonCard";
import {Box} from "components/Box";
import {BuyBonModal} from "components/BuyBonModal";
import {buyVoucherService, fetchVoucherService, IBon} from "services/vouchers";
import {Col, Row} from "react-grid-system";
import React, {useState} from "react";
import {toast} from "react-toastify";
import {useAuthDispatchContext} from "../../contexts/AuthContext";

export const BuyBonsView = () => {
  const [bons, setBons] = useState<IBon[]>([]);
  const [isOpen, setIsOpen] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [currentBon, setCurrentBon] = useState<IBon | null>(null);
  const {refreshUserProfile} = useAuthDispatchContext();

  const onHandleBuyClick = (bon: IBon) => {
    setCurrentBon(bon);
    setIsOpen(true);
  };

  const handleConfirm = () => {
    if (!currentBon) return
    buyVoucherService({voucher: {voucher_id: currentBon.id}})
      .then(() => {
        setBons(prevState => prevState.filter(el => el.id !== currentBon.id))
        refreshUserProfile()
          .then(() => toast.success('Bon został wykupiony.'))
      })
      .catch(() => toast.error('Nie udało się kupić bona.'))
      .finally(() => {
        setCurrentBon(null);
        setIsOpen(false);
      })
  };

  const fetchVouchers = async () => {
    const response = await fetchVoucherService();
    setBons(response.data);
    setTotalCount(response.pagination.count);
  };

  const translateWord = () => {
    if (totalCount === 1) return "bon";
    if (totalCount > 1 && totalCount < 5) return "bony";
    if (totalCount > 4) return "bonów";
  };

  React.useEffect(() => {
    fetchVouchers();
  }, []);

  return (
    <>
      <Box px={2} pt={6}>
        {/* <h1>
          Obecnie jest {totalCount} {translateWord()} z przecenami.
        </h1> */}
        <Row>
          {bons.length > 0 ? (
            bons.map((item, idx) => (
              <Col sm={4} key={idx}>
                <BonCard
                  {...item}
                  variant="buy"
                  onHandleBuyClick={() => onHandleBuyClick(item)}
                />
              </Col>
            ))
          ) : (
            <p>Brak bonów do kupienia.</p>
          )}
        </Row>
      </Box>

      {currentBon && (
        <BuyBonModal
          isOpen={isOpen}
          onCancelClick={() => setIsOpen(false)}
          handleConfirm={handleConfirm}
          item={currentBon}
        />
      )}
    </>
  );
};
