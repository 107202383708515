import { Box } from "components/Box";
import { BorderedCard } from "components/BorderedCard";
import { Input } from "components/Input";
import { Flex } from "./Flex";
import { Typography } from "components/Typography";
import { Like } from "components/Like";
import { DatePickerInput } from "components/DatePickerInput";
import { ReactComponent as OrocoinIcon } from "icons/orocoin.svg";
import { Button } from "./Button";
import { useForm, Controller } from "react-hook-form";
import { INewCampaing } from "services/campaign";
import { campaignSchema } from "schemas/campaign";
import { yupResolver } from "@hookform/resolvers/yup";
import styled from "styled-components";

interface IAddCampaignForm {
  createCampaigns: (data: INewCampaing) => void;
}

interface IRowFields {
  bordered?: boolean;
}

const WrapperField = styled(Box)`
  width: 100%;
`;
const RowFields = styled(Flex)<IRowFields>`
  ${({ theme, bordered }) => `
      padding: ${theme.space[6]}px 0;
    ${
      bordered &&
      `
        border-bottom: 1px solid ${theme.palette.neutral.light};
      `
    }
  `}
`;
const Field = styled.div`
  width: 48%;
`;

const defaultValues = {
  name: "",
  start_at: "",
  end_at: "",
  facebook_link: "",
  tokens_amount: "",
  tokens_gratification: "",
  status: "active",
};

export const AddCampaignForm: React.FC<IAddCampaignForm> = ({
  createCampaigns,
}) => {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<INewCampaing>({
    defaultValues,
    resolver: yupResolver(campaignSchema),
  });

  const onSubmit = (data: INewCampaing) => {
    createCampaigns(data);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <BorderedCard>
        <WrapperField px={15} py={6}>
          <RowFields justifyContent="space-between" bordered>
            <Field>
              <Box mb={2}>
                <Typography variant="h3">Nazwa kampanii</Typography>
              </Box>
              <Input
                error={errors.name && errors.name.message}
                {...register("name")}
              />
            </Field>
            <Field>
              <Box mb={2}>
                <Typography variant="h3">Link do posta</Typography>
              </Box>
              <Input
                error={errors.facebook_link && errors.facebook_link.message}
                {...register("facebook_link")}
              />
            </Field>
          </RowFields>

          <RowFields justifyContent="space-between" bordered>
            <Field>
              <Box mb={2}>
                <Typography variant="h3">Czas trwania</Typography>
              </Box>
              <Flex justifyContent="space-between" alignItems="center">
                <WrapperField>
                  <Controller
                    control={control}
                    name="start_at"
                    render={({ field }) => (
                      <DatePickerInput
                        error={!!errors.start_at}
                        day={field.value ? new Date(field.value) : undefined}
                        onChange={(date) =>
                          field.onChange(date ? new Date(date) : undefined)
                        }
                      />
                    )}
                  />
                </WrapperField>

                <Box px={2}>-</Box>
                <WrapperField>
                  <Controller
                    control={control}
                    name="end_at"
                    render={({ field }) => (
                      <DatePickerInput
                        error={!!errors.end_at}
                        day={field.value ? new Date(field.value) : undefined}
                        onChange={(date) =>
                          field.onChange(date ? new Date(date) : undefined)
                        }
                      />
                    )}
                  />
                </WrapperField>
              </Flex>
            </Field>
            <Field>
              <Box mb={2}>
                <Typography variant="h3">Wartość akcji</Typography>
              </Box>
              <Flex justifyContent="center" alignItems="center">
                <Like like={1} />
                <Box px={2}>=</Box>
                <Input
                  error={
                    errors.tokens_gratification &&
                    errors.tokens_gratification.message
                  }
                  {...register("tokens_gratification")}
                />
                <Box ml={2}>
                  <OrocoinIcon />
                </Box>
              </Flex>
            </Field>
          </RowFields>

          <RowFields justifyContent="space-between" alignItems="center">
            <Field>
              <Box mb={2}>
                <Typography variant="h3">Budżet</Typography>
              </Box>
              <Flex>
                <Input
                  error={errors.tokens_amount && errors.tokens_amount.message}
                  {...register("tokens_amount")}
                />
                <Box ml={2}>
                  <OrocoinIcon />
                </Box>
              </Flex>
            </Field>
            <Flex justifyContent="center" alignItems="center">
              <Button type="submit" label="Aktywuj kampanie" />
            </Flex>
          </RowFields>
        </WrapperField>
      </BorderedCard>
    </form>
  );
};
