import React from "react";
import { Flex } from "components/Flex";
import { Box } from "components/Box";
import { Card } from "components/Card";
import styled from "styled-components";
import { Typography } from "./Typography";
import { InfoBox } from "components/InfoBox";
import { CountCoin } from "components/CountCoin";
import { ICampaing } from "services/campaign";
import { format } from "date-fns";

const Wrapper = styled(Card)`
  ${({ theme }) => `
    margin-bottom: ${theme.space[8]}px;
    border-radius: 10px;
    overflow: hidden;
  `}
`;
const Image = styled.div`
  background-color: #f2f2f2;
  width: 100%;
  height: 143px;
`;
const Heading = styled(Typography)`
  font-size: 18px;
`;
const Text = styled(Typography)`
  font-size: 15px;
`;
const StyledFlex = styled(Flex).attrs({ justifyContent: "space-between" })`
  border-top: 1px solid rgba(33, 33, 90, 0.15);
  padding: 6px 0;
`;

export const Campaign: React.FC<ICampaing> = (props) => (
  <Wrapper>
    <Image />
    <Box p={6}>
      <Box mb={2}>
        <Heading variant="h3">{props.name}</Heading>
      </Box>
      <Box mb={2}>
        <Text variant="body2">
          {format(new Date(props.start_at), "dd.MM.yy")} -{" "}
          {format(new Date(props.end_at), "dd.MM.yy")}
        </Text>
      </Box>
      <StyledFlex>
        <Text variant="body2">Like (10/25)</Text>
        <CountCoin label={props.tokens_gratification} />
      </StyledFlex>
      <StyledFlex>
        <Text variant="body2">Budżet</Text>
        <CountCoin label={props.tokens_amount} />
      </StyledFlex>
      <Box mt={2}>
        <InfoBox variant={props.status} />
      </Box>
    </Box>
  </Wrapper>
);
