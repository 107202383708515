// import { ErrorHelperText } from "components/ErrorHelperText";
// import { FieldLabel as Label } from "components/FieldLabel";
// import { useOnClickOutside } from "hooks/onClickOutside";
import React, { InputHTMLAttributes, useRef, useState } from "react";
import styled from "styled-components";
import { Label } from "components/Label";

export interface IHint {
  label: string;
  value: string;
}

export interface TextFieldType extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  error?: string;
}

interface StyledInputI {
  error?: TextFieldType["error"];
}

const unitWidthInPx = 44;
const iconSizeInPx = 24;
const iconPositionRight = 4;

const Root = styled.div`
  width: 100%;
`;

const InputWrapper = styled.div`
  position: relative;
  width: 100%;
`;

const Unit = styled.div`
  ${({ theme }) => `
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: ${unitWidthInPx}px;

    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 8px;
      left: 0;
      height: 24px;
      width: 1px;
      background-color: ${theme.palette.neutral.medium};
    }
  `};
`;

const Icon = styled.div`
  position: absolute;
  right: ${iconPositionRight}px;
  top: 8px;
  display: flex;
  height: ${iconSizeInPx}px;
  width: ${iconSizeInPx}px;
`;

const StyledInput = styled.input<StyledInputI>`
  ${({ theme, error }) => `
    display: block;
    width: 100%;
    height: 40px;
    padding: 0 16px;
    font-size: 14px;
    background-color: #EFEFEF;
    color: ${theme.palette.text.primary};
    border-radius: 4px;
    outline: none;
    ${
      error
        ? `
      border: 1px solid ${theme.palette.text.error}
      `
        : `
        border: none;
      `
    }
  `};
`;

export const Input = React.forwardRef<HTMLInputElement, TextFieldType>(
  ({ label, error, disabled, ...props }, ref) => {
    return (
      <Root>
        {label ? <Label>{label}</Label> : null}
        <InputWrapper>
          <StyledInput
            ref={ref}
            type="text"
            error={error}
            disabled={disabled}
            {...props}
          />
        </InputWrapper>
      </Root>
    );
  }
);

Input.displayName = "Input";
