import {useEffect, useState} from "react";
import { BonCard } from "components/BonCard";
import { Box } from "components/Box";
import {fetchVoucherCodesService, IBon} from "services/vouchers";
import { Col, Row } from "react-grid-system";
import {toast} from "react-toastify";

export const BoughtBonsView = () => {
  const [bons, setBons] = useState<{code: string; voucher: IBon}[]>([]);

  useEffect(() => {
    fetchVoucherCodesService()
      .then(res => {
        setBons(res.data)
      })
      .catch(err => toast.error('Nie udało się pobrać bonów.'))
  }, [])
  return (
    <Box px={2} pt={6}>
      <Row>
        {bons.length > 0 ? (
          bons.map((item, idx) => (
            <Col sm={4} key={idx}>
              <BonCard variant="my" {...item.voucher} />
            </Col>
          ))
        ) : (
          <p>nie kupiłeś żadnych bonów.</p>
        )}
      </Row>
    </Box>
  );
};
