import * as yup from "yup";

export const registerSchema = yup.object().shape({
  first_name: yup.string().required(),
  last_name: yup.string().required(),
  birthdate: yup.string().required(),
  email: yup.string().required("Pole email jest wymagane."),
  password: yup.string().required(),
  password_confirmation: yup.string()
  .oneOf([yup.ref('password'), null], 'Passwords must match'),

  confirm_regulations: yup.boolean().oneOf([true],'Proszę zapoznać się z regulaminem.'),
}).required();