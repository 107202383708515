import React from "react";
import { Switch } from "react-router";
import { Route } from "types/route";
import { RouteWithSubRoutes } from "./RouteWithSubRoutes";

interface IProps {
  routes: Route[];
}

export const Router: React.FC<IProps> = ({ routes }) => (
  <Switch>
    {routes.map((route: Route) => (
      <RouteWithSubRoutes key={route.path} {...route} />
    ))}
  </Switch>
);
