import { useState, useEffect } from "react";
import { BonCard } from "components/BonCard";
import { Box } from "components/Box";
import { Flex } from "components/Flex";
import { Col, Row } from "react-grid-system";
import { fetchMyVoucherService, IBon } from "services/vouchers";

export const YoursBonsView = () => {
  const [bons, setBons] = useState<IBon[]>([]);

  const fetchMyVouchers = async () => {
    const response = await fetchMyVoucherService();
    setBons(response.data);
  };

  useEffect(() => {
    fetchMyVouchers();
  }, []);

  return (
    <>
      <Box px={2} pt={6}>
        {/* <Flex justifyContent="space-between" alignItems="center">
          <h1>Twoje kampanie</h1>
        </Flex> */}
        <Row>
          {bons.map((item, idx) => (
            <Col sm={4} key={idx}>
              <BonCard variant="my" {...item} />
            </Col>
          ))}
        </Row>
      </Box>
    </>
  );
};
