import { useState, ChangeEvent } from "react";
import { BorderedCard } from "components/BorderedCard";
import { Box } from "components/Box";
import { Flex } from "components/Flex";
import { Button } from "components/Button";
import { Input } from "components/Input";
import { Typography } from "components/Typography";
import { ChangePasswordServie } from "services/auth";
import { ConfirmPasswordSchema } from "schemas/ConfirmPassword";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useLocation, useHistory } from "react-router-dom";
import queryString from "query-string";
import { toast } from "react-toastify";
import styled from "styled-components";

interface IForm {
  password: string;
  password_confirmation: string;
}

const StyledBorderedCard = styled(BorderedCard)`
  width: 600px;
  margin: 0 auto;
`;
const StyledBox = styled(Box)`
  width: 100%;
`;
const StyledFlex = styled(Flex)`
  width: 100%;
`;
const StyledInput = styled(Input)`
  width: 95%;
`;

export const ChangePasswordView = () => {
  const [successChangedMessage, setSuccessChangedMessage] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const { token } = queryString.parse(location.search);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IForm>({
    resolver: yupResolver(ConfirmPasswordSchema),
  });

  const handleChangePassword = (params: IForm) => {
    if (!token) return;
    const data = { ...params, reset_password_token: `${token}` };
    ChangePasswordServie(data)
      .then(() => {
        toast.success("Hasło zostało zmienione.");
      })
      .catch(() => toast.error("Nie udało się zmienić hasła."))
      .finally(() => {
        setSuccessChangedMessage(true);
      });
  };

  return (
    <StyledBorderedCard>
      <StyledBox p={8}>
        {successChangedMessage ? (
          <>
            <Typography variant="h1">Hasło zostało zmienione</Typography>
            <Flex justifyContent="flex-end">
              <Box mt={9}>
                <Button
                  label="Zaloguj się"
                  onClick={() => history.push("/login")}
                />
              </Box>
            </Flex>
          </>
        ) : (
          <>
            <Box mt={6} mb={8}>
              <Typography variant="h1">Ustaw nowe hasło</Typography>
            </Box>
            <form onSubmit={handleSubmit(handleChangePassword)}>
              <StyledFlex justifyContent="space-between">
                <StyledInput
                  type="password"
                  label="Nowe hasło"
                  placeholder="Nowe hasło"
                  error={errors.password && errors.password.message}
                  {...register("password")}
                />
                <StyledInput
                  type="password"
                  label="Powtórz hasło"
                  placeholder="Powtórz nowe hasło"
                  error={
                    errors.password_confirmation &&
                    errors.password_confirmation.message
                  }
                  {...register("password_confirmation")}
                />
              </StyledFlex>
              <Flex justifyContent="flex-end">
                <Box mt={9}>
                  <Button label="Zmień hasło" type="submit" />
                </Box>
              </Flex>
            </form>
          </>
        )}
      </StyledBox>
    </StyledBorderedCard>
  );
};
