import { IMenuItem } from "components/Menu";
import { useRouteMatch } from "react-router-dom";

export const useMenu = (menuItems: Array<IMenuItem>) => {
  return menuItems.map(({ label, path, items }) => {
    return {
      label,
      path,
      items: items
        ? items.map((item: IMenuItem) => ({
            ...item,
            current: !!useRouteMatch(String(item.path)),
          }))
        : undefined,
      current: !!useRouteMatch({ path }),
    };
  });
};
