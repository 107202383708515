import { Menu } from "components/Menu";
import { Router } from "components/Router";
import { useMenu } from "hooks/menu";
import React from "react";
import { Col, Row } from "react-grid-system";
import { Route } from "types/route";
import { useProfileNavigate } from "hooks/profileNavigationPerRole";
import styled from "styled-components";

const StyledCol = styled(Col)`
  background-color: #f7f7f7;
  height: 100vh;
`;

export const ProfileView: React.FC<{
  routes: Array<Route>;
}> = ({ routes: subRoutes }) => {
  const { navigationItems } = useProfileNavigate();

  const menuItems = useMenu([
    { label: "Mój profil", path: "/profile/info" },
    ...navigationItems(),
  ]);

  return (
    <>
      <Row>
        <StyledCol sm={2}>
          <Menu items={menuItems} />
        </StyledCol>
        <Col sm={10}>
          <Router routes={subRoutes} />
        </Col>
      </Row>
    </>
  );
};
