import { Box } from "components/Box";
import { BorderedCard } from "components/BorderedCard";
import { useAuthDispatchContext } from "contexts/AuthContext";
import { useForm } from "react-hook-form";
import { Link, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { AuthUserService } from "services/auth";
import { fetchMyData } from "services/user";
import { isNetworkError } from "types/error";
import styled from "styled-components";
import { Input } from "components/Input";
import { flex } from "styled-system";
import { Flex } from "components/Flex";
import { Button } from "components/Button";
import { Typography } from "components/Typography";

interface ILoginFormData {
  email: string;
  password: string;
}

export interface INetworkError {
  error: string;
}

const StyledBorderedCard = styled(BorderedCard)`
  display: block;
  width: 600px;
  margin: 0 auto;
`;
const StyledInput = styled(Input)`
  width: 95%;
`;
const StyledTypography = styled(Typography)`
  text-decoration: underline;
  cursor: pointer;
`;

export const LoginView = () => {
  const history = useHistory();
  const { setToken, setUserProfile } = useAuthDispatchContext();
  const { register, handleSubmit } = useForm<ILoginFormData>({
    defaultValues: {
      email: "",
      password: "",
    },
  });

  const getUserData = async () => {
    const response = await fetchMyData();
    setUserProfile(response);
  };

  const onSubmit = handleSubmit(async (data) => {
    if (data.email.length && data.password.length) {
      try {
        const response = await AuthUserService({ user: data });
        setToken(response.token);
        getUserData();
        history.push("/points");
      } catch (error: unknown) {
        if (isNetworkError(error)) {
          toast.error(error.error);
        } else {
          console.error(error);
        }
      }
    }
  });

  return (
    <Flex flexDirection="column" alignItems="center">
      <form onSubmit={onSubmit}>
        <StyledBorderedCard>
          <Box p={8}>
            <Box mb={9}>
              <Typography variant="h1">Zaloguj się</Typography>
            </Box>
            <Flex>
              <StyledInput
                label="E-mail"
                type="text"
                placeholder="Email"
                {...register("email")}
              />
              <Input
                label="Hasło"
                type="password"
                placeholder="Hasło"
                {...register("password")}
              />
            </Flex>
            <Box py={6}>
              <Flex justifyContent="flex-end">
                <StyledTypography
                  variant="body"
                  onClick={() => history.push("/password-reset")}
                >
                  Nie pamiętasz hasła?
                </StyledTypography>
              </Flex>
            </Box>
            <Flex justifyContent="flex-end">
              <Box mt={4}>
                <Button type="submit" label="Zaloguj się" />
              </Box>
            </Flex>
          </Box>
        </StyledBorderedCard>
      </form>
      <Flex mt={10}>
        <Box mr={4}>
          <Link to="/regulamin">Regulamin</Link>
        </Box>
        <Link to="/privacy-policy">Polityka prywatności</Link>
      </Flex>
    </Flex>
  );
};
