import { Button } from "components/Button";
import { Input } from "components/Input";
import { Avatar } from "components/Avatar";
import { Navbar } from "components/Navbar";

export const HomeView = () => (
  <>
    <Avatar />
    <Input label="E-mail" />
    <Button label="Zarejestruj się" />
    <Button variant="secondary" label="Zaloguj się" />
    <Button variant="third" label="Nowa kompania" />
  </>
);
