import React from "react";
import { useAuthStateContext } from "contexts/AuthContext";
import { Redirect, Route } from "react-router-dom";
import { Route as RouteProps } from "types/route";

export const RouteWithSubRoutes = (route: RouteProps) => {
  /** Authenticated flag */
  // const { token } = useAuthStateContext();
  // const authenticated = token !== null;

  const authenticated = !!localStorage.getItem("token");

  // console.log("route.path", route.path);

  return (
    <Route
      path={route.path}
      render={(props) => {
        return route.redirect ? (
          <Redirect to={route.redirect} />
        ) : route.private ? (
          authenticated ? (
            route.component && (
              <route.component {...props} routes={route.routes} />
            )
          ) : (
            <Redirect to="/login" />
          )
        ) : (
          route.component && (
            <route.component {...props} routes={route.routes} />
          )
        );
      }}
    />
  );
};
