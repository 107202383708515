import { ToastContainer } from "react-toastify";
import { Router } from "components/Router";
import { routes } from "routes";
import { Navbar } from "components/Navbar";
import { useNavbar } from "hooks/navbar";
import { Container } from "react-grid-system";
import { useHistory } from "react-router-dom";
import { useProfileNavigate } from "hooks/profileNavigationPerRole";

export const App = () => {
  const history = useHistory();
  const { navbarLinks } = useNavbar();
  const { navigationItems } = useProfileNavigate();

  const handleMoveProfile = (page: string) => {
    history.push(`${page}`);
  };

  const parseProfileNavigation = () =>
    navigationItems().map(({ label, path }) => ({
      label,
      onClick: () => handleMoveProfile(path),
    }));

  return (
    <div>
      <Container>
        <Navbar
          links={navbarLinks}
          profileDropdownItems={parseProfileNavigation()}
        />
        <Router routes={routes} />
      </Container>
      <ToastContainer position="bottom-right" />
    </div>
  );
};

export default App;
