import {fetchAPI} from "utilities/fetchAPI";
import {VOUCHERS_URL, MY_VOUCHERS_URL, VOUCHER_BUY, VOUCHER_CODES_URL} from "constans/path";
import {IPagination} from 'types/pagination';

export interface IVoucher {
  kind?: string;
  name: string;
  money_value?: number | null;
  percent_value?: number | null;
  voucher_price: number;
  count: number;
  website_address: string;
  start_at: string;
  end_at: string;
  image: string;
  voucher_codes: string;
}

export interface IBon {
  id: number;
  image: string;
  name: string;
  website_address: string;
  start_at: string;
  end_at: string;
  money_value: string | null;
  percent_value: string | null;
  voucher_price: number;
  count: number;
}

export const createVoucherService = async (voucher: IVoucher) => {
  const response = await fetchAPI(VOUCHERS_URL, {
    method: "POST",
    headers: {
      Accept: "application/json",
    },
    body: JSON.stringify({voucher: {...voucher}}),
  });
  return response
}

interface IResponse {
  pagination: IPagination;
  data: IBon[];
}

interface IVoucherCodesResponse {
  pagination: IPagination;
  data: {
    code: string
    voucher: IBon
  }[];
}

export const fetchVoucherService = () => fetchAPI<IResponse>(VOUCHERS_URL);

export const fetchMyVoucherService = () => fetchAPI<IResponse>(MY_VOUCHERS_URL);

export const fetchVoucherCodesService = () => fetchAPI<IVoucherCodesResponse>(VOUCHER_CODES_URL);

export interface IBuyVoucher {
  voucher: {
    voucher_id: number
  }
}

interface IBuyVoucherResponse {
  data: {
    code: string,
    voucher: {
      id: number,
      image: null | string,
      name: string,
      website_address: string,
      start_at: string,
      end_at: string,
      money_value: null | string,
      percent_value: string,
      voucher_price: number,
      count: number
    }
  }
}

export const buyVoucherService = (data: IBuyVoucher) => fetchAPI<IBuyVoucherResponse>(VOUCHER_BUY, {
  method: "POST",
  headers: {
    Accept: "application/json",
  },
  body: JSON.stringify(data),
});
