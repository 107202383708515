import { Card } from "components/Card";
import { Button } from "components/Button";
import { Flex } from "components/Flex";
import { CountCoin } from "components/CountCoin";
import { Typography } from "components/Typography";
import { Box } from "components/Box";
import { IBon } from "services/vouchers";
import { format } from "date-fns";
import styled from "styled-components";
import noPhoto from "icons/brak-zdjecia.png";

interface IProps extends IBon {
  variant: "my" | "buy" | "sell";
  onHandleBuyClick?: () => void;
}

interface IImage {
  url?: string;
}

const StyledCard = styled(Card)`
  margin-bottom: 24px;
`;
const Image = styled.div<IImage>`
  ${({ url }) => `
    height: 212px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    ${
      url
        ? `
      background-image: url(${url});
    `
        : `
        background-image: url(${noPhoto});
    `
    }
  `}
`;
const StyledTypography = styled(Typography)`
  text-align: center;
  color: #2a2a2a;
`;
const ExpirationDate = styled.p`
  font-size: 10px;
  color: #2a2a2a;
  text-align: center;
  opacity: 0.5;
`;
const LeftBons = styled.p`
  text-align: center;
  > span {
    font-weight: bold;
  }
`;

export const BonCard: React.FC<IProps> = ({
  name,
  count,
  voucher_price,
  end_at,
  image,
  variant,
  onHandleBuyClick,
}) => (
  <StyledCard>
    <Image url={image} />
    <Box p={6}>
      <Box mb={4}>
        <StyledTypography variant="h3">{voucher_price} zł</StyledTypography>
      </Box>
      <Box mb={6}>
        <StyledTypography variant="body2">
          {name} - {variant}
        </StyledTypography>
      </Box>

      {variant === "buy" && (
        <Flex justifyContent="space-between">
          <CountCoin label={count} />
          <Button
            variant="secondary"
            label="Kup bon"
            onClick={onHandleBuyClick}
          />
        </Flex>
      )}
      <hr />

      <LeftBons>
        Pozostało <span>7 bonów</span>
      </LeftBons>

      <ExpirationDate>
        Data ważności bona: {format(new Date(end_at), "dd.MM.yyyy")}
      </ExpirationDate>
    </Box>
  </StyledCard>
);
