import { http } from "./http";

export const fetchAPI = async <T>(url: string, options?: RequestInit) =>
  http<T>(url, {
    ...options,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
      ...options?.headers,
    },
  });