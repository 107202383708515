import { useEffect, useState } from "react";
import { Box } from "components/Box";
import { Campaign } from "components/Campaign";
import { fetchMyCampaingsApi, ICampaing } from "services/campaign";
import { Col, Row } from "react-grid-system";

export const YoursCampaignsView = () => {
  const [campaignsList, setCampaignsList] = useState<ICampaing[]>([]);

  const getCampaigns = () => {
    fetchMyCampaingsApi().then(({ data }) => setCampaignsList(data));
  };

  useEffect(() => {
    getCampaigns();
  }, []);

  return (
    <Box px={2} pt={6}>
      {/* <h1>Twoje kampanie</h1> */}
      <Row>
        {campaignsList.map((item) => (
          <Col sm={4} key={item.id}>
            <Campaign {...item} />
          </Col>
        ))}
      </Row>
    </Box>
  );
};
