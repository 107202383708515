import React from "react";
import Select from "react-select";
import styled from "styled-components";

interface IProps {
  options: IOptions[];
  onChange: (data: any) => void;
}
interface IOptions {
  value: string;
  label: string;
}

export const SelectField: React.FC<IProps> = ({ options, onChange }) => {
  return (
    <Select options={options} defaultValue={options[0]} onChange={onChange} />
  );
};
