import { Avatar } from "components/Avatar";
import { Box } from "components/Box";
import { Flex } from "components/Flex";
import { Typography } from "components/Typography";
import { useConnectUserFB } from "hooks/connectUserFB";
import { useAuthStateContext } from "contexts/AuthContext";
import { USER_ROLE } from "enums/role";
import { BorderedCard } from "components/BorderedCard";
import styled from "styled-components";

const Root = styled(Box)`
  background-color: #fff;
`;

const Message = styled.div`
  background-color: #408bea;
  color: #fff;
  padding: 10px;
  border-radius: 8px;
  width: 500px;
  text-align: center;
`;
const StyledBorderedCard = styled(BorderedCard)`
  ${({ theme }) => `
    padding: 0 ${theme.space[4]}px;
  `}
`;

export const SettingsView = () => {
  const { userProfile } = useAuthStateContext();
  const { connectFbAccount } = useConnectUserFB();

  return (
    <Box p={6}>
      {userProfile?.role.kind === USER_ROLE.user ? (
        <Message onClick={connectFbAccount}>Połącz konto z facebookiem</Message>
      ) : (
        <Root>
          <Box>
            <StyledBorderedCard>
              <Avatar size="default" />
              <Box p={4}>
                <Flex flexDirection="column">
                  <Box mb={1}>
                    <Typography variant="body2">
                      {userProfile
                        ? `${userProfile.first_name} ${userProfile.last_name}`
                        : "Nieznany użytkownik"}
                    </Typography>
                  </Box>
                  <Box mb={1}>
                    <Typography variant="body2">
                      email: {userProfile?.email}
                    </Typography>
                  </Box>
                  <Typography variant="body2">
                    Data urodzenia: 26.08.1990
                  </Typography>
                </Flex>
              </Box>
            </StyledBorderedCard>
          </Box>
        </Root>
      )}
    </Box>
  );
};
