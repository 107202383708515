import { Box } from "components/Box";
import styled from "styled-components";

interface ILike {
  like: number;
}

const Root = styled(Box)`
  ${({ theme }) => `
    background-color: ${theme.palette.primary.secondary};
    color: ${theme.palette.neutral.blue};
    white-space: nowrap;
    border-radius: 50px;
  `}
`;

export const Like: React.FC<ILike> = ({ like }) => (
  <Root px={6} py={2}>
    {like} Like
  </Root>
);
