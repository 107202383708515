import { Box } from "components/Box";
import { Card } from "components/Card";
import { Typography } from "components/Typography";
import { useDropdown } from "hooks/dropdown";
import React from "react";
import styled from "styled-components";
import { Flex } from "./Flex";
import { useHistory } from "react-router-dom";
import { LogoutUserService } from "services/auth";

export interface IDropdown {
  items: Array<{
    label: string;
    onClick: () => any;
  }>;
}

const Root = styled.div`
  position: relative;
`;

const WrapperChildren = styled.div`
  cursor: pointer;
`;

const WrapperDropdown = styled.div`
  ${({ theme }) => `
    right: 0;
    position: absolute;
    top: 100%;
    margin-top: ${theme.space[2]}px;
    min-width: 180px;
    z-index: 1000;
  `}
`;

const StyledTypography = styled(Typography)`
  ${({ theme }) => `
    padding: ${theme.space[2]}px 0px;
    white-space: nowrap;
    cursor: pointer;
  `}
`;
const Logout = styled(Typography)`
  ${({ theme }) => `
  border-top: 1px solid rgba(33, 33, 90, 0.2);
  padding-top: 16px;
  color: ${theme.palette.text.error};
  text-align: center;
  font-weight: 700;
  cursor: pointer;
  `}
`;

export const Dropdown: React.FC<IDropdown> = ({ items, children }) => {
  const { buttonRef, optionsRef, isVisible, setIsVisible } = useDropdown(false);
  const history = useHistory();

  const handleClick = (onClick: () => any) => () => {
    onClick();
    setIsVisible(false);
  };

  const handleLogout = async () => {
    const token = localStorage.getItem("token");
    if (token) {
      localStorage.removeItem("token");
      localStorage.removeItem("likey-currentUser");
      history.push("/login");
      await LogoutUserService();
    }
  };

  return (
    <Root>
      <WrapperChildren ref={buttonRef} role="button" aria-hidden="true">
        {children}
      </WrapperChildren>
      {isVisible && (
        <WrapperDropdown ref={optionsRef}>
          <Card>
            <Box p={5}>
              <Flex justifyContent="center">
                <Box mb={3}>
                  <StyledTypography
                    variant="h3"
                    onClick={() => {
                      history.push("/profile/info");
                      setIsVisible(false);
                    }}
                  >
                    Twój profil
                  </StyledTypography>
                </Box>
              </Flex>
              <Box pb={3} pl={6}>
                {items.map(({ label, onClick }) => (
                  <div
                    key={label}
                    onClick={handleClick(onClick)}
                    aria-hidden="true"
                    role="button"
                  >
                    <StyledTypography variant="body">{label}</StyledTypography>
                  </div>
                ))}
              </Box>
              <Logout variant="body2" onClick={handleLogout}>
                Wyloguj się
              </Logout>
            </Box>
          </Card>
        </WrapperDropdown>
      )}
    </Root>
  );
};
