import React from "react";
import { Box } from "components/Box";
import { Flex } from "components/Flex";
import { Typography } from "components/Typography";
import { Button } from "components/Button";
import { Modal } from "components/Modal";
import { format } from "date-fns";
import { IVoucher } from "services/vouchers";
import styled from "styled-components";

interface IProps {
  voucher: IVoucher;
  isOpen: boolean;
  createVoucher: (voucher: IVoucher) => void;
  onCancelClick: () => void;
}
interface IImage {
  url: string;
}

const StyledBox = styled(Box)`
  max-width: 600px;
`;
const Image = styled.img<IImage>`
  ${({ url }) => `
    width: 100%;
    height: 212px;
    overflow: hidden;
    background-image: url(${url});
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  `}
`;
const StyledTypography = styled(Typography)`
  ${({ theme }) => `
    margin-bottom: ${theme.space[2]}px;
  `}
`;

export const CreateCreateVoucherModal: React.FC<IProps> = ({
  voucher,
  isOpen,
  createVoucher,
  onCancelClick,
}) => {
  return (
    <Modal isOpen={isOpen} onCancelClick={onCancelClick}>
      <StyledBox p={6}>
        <Box mb={2}>
          <Typography variant="h3" textAlign="center">
            Proszę sprawidzić dane, przed stworzeniem Bonu.
          </Typography>
        </Box>
        <Typography variant="body2" textAlign="center">
          Po stworzeniu bonu nie będzie możliwości edycji.
        </Typography>
        {console.log("voucher from modal", voucher)}

        <Box py={5}>
          <Box mb={5}>
            <Image url={voucher.image} />
          </Box>
          <Flex justifyContent="space-between">
            <Box mr={5}>
              <StyledTypography variant="body2">
                Opis bonu: <strong>{voucher.name}</strong>
              </StyledTypography>
              <StyledTypography variant="body2">
                Wartość bona:{" "}
                <strong>{voucher.money_value || voucher.percent_value}</strong>
              </StyledTypography>
              <StyledTypography variant="body2">
                Koszt bona: <strong>{voucher.voucher_price}</strong>
              </StyledTypography>
            </Box>
            <Box>
              <StyledTypography variant="body2">
                Adres strony: <strong>{voucher.website_address}</strong>
              </StyledTypography>
              <StyledTypography variant="body2">
                Unikalne kody: <strong>{voucher.voucher_codes}</strong>
              </StyledTypography>
              <StyledTypography variant="body2">
                Data ważności:{" "}
                <strong>
                  od {format(new Date(voucher.start_at), "dd.MM.yyyy")} do{" "}
                  {format(new Date(voucher.end_at), "dd.MM.yyyy")}
                </strong>
              </StyledTypography>
            </Box>
          </Flex>
        </Box>

        <Flex justifyContent="flex-end">
          <Box mr={2}>
            <Button
              variant="primary"
              label="Stwórz"
              onClick={() => createVoucher(voucher)}
            />
          </Box>
          <Button variant="secondary" label="Anuluj" onClick={onCancelClick} />
        </Flex>
      </StyledBox>
    </Modal>
  );
};
