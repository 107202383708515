import { HistoryPaymentRow } from "components/HistoryPaymentRow";
import { Box } from "components/Box";
import { Flex } from "components/Flex";
import { Typography } from "components/Typography";
import { Col, Row } from "react-grid-system";
import {useEffect, useState} from "react";
import {fetchWalletEvents, IResponseWalletEvents, IWalletEvent} from "../services/payments";
import {formatDate} from "../utilities/date";
import {Pagination} from "./Pagination";
import {usePagination} from "../hooks/pagination";

const data = [
  {
    date: "22.01.2022",
    label: "Doładowanie konta",
    variant: "plus" as const,
    amount: 300,
  },
  {
    date: "22.01.2022",
    label: "Zakup kampanii",
    subLabel: "Nazwa kampani",
    variant: "minus" as const,
    amount: 150,
  },
  {
    date: "22.01.2022",
    label: "Wypłata środków",
    variant: "minus" as const,
    amount: 500,
  },
  {
    date: "22.01.2022",
    label: "Zakup bona",
    subLabel: "Nazwa bona",
    variant: "minus" as const,
    amount: 50,
  },
];

export const HistoryTransations = () => {
  const [walletEvents, setWalletEvents] = useState<IWalletEvent[]>([])
  const [totalCount, setTotalCount] = useState(0);
  const { currentPage, handleClick, itemsPerPage } = usePagination();

  useEffect(() => {
    fetchWalletEvents(currentPage, itemsPerPage)
      .then(response => {
        setWalletEvents(response.data)
        setTotalCount(response.pagination.count);
      })
      .catch(err => console.log(err))
  }, [currentPage])

  return (
    <>
      <Box px={6} py={3} mb={3}>
        <Row>
          <Col sm={4}>
            <Typography variant="h4">Data</Typography>
          </Col>
          <Col sm={4}>
            <Typography variant="h4">Typ transakcji</Typography>
          </Col>
          <Col sm={3}>
            <Flex justifyContent="flex-end">
              <Box ml={5}>
                <Typography variant="h4">Kwota</Typography>
              </Box>
            </Flex>
          </Col>
        </Row>
      </Box>
      {walletEvents.map((item, idx) => {
        const amount = item.amount.toString()
        return (
          <HistoryPaymentRow
            key={idx}
            date={formatDate(new Date(item.created_at))}
            label={item.name}
            amount={+(amount[0] === '-' ? amount.slice(1) : amount)}
            variant={item.amount < 0 ? 'minus' : 'plus'}
          />
        )
      })}
      <Flex justifyContent="flex-end">
        <Pagination
          current={currentPage}
          pages={totalCount}
          itemsPerPage={itemsPerPage}
          onPageClick={handleClick}
        />
      </Flex>
    </>
  );
};
